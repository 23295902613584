import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./styles/app.sass";
import Page from "./components/Page";
import { routes } from "./routes";


const App = () => {
  return (
      <Router>
        <Switch>
          {routes?.map((route) => (
            <Route
              key={route.path}
              exact
              path={route?.path}
              render={() =>
                route?.isPublic ? (
                  <route.component />
                ) : (
                  <Page>
                    <route.component />
                  </Page>
                )
              }
            />
          ))}
        </Switch>
      </Router>
  );
};

export default App;
