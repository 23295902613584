import contractData from '../contracts/sellItContract.json'
import polygonContractData from '../contracts/polygonContract.json'
import Web3 from 'web3'
import { ethers } from 'ethers'
const contractAddress = '0x6fEc0811928b4890624F67Ff1269C2AE24247943'
const rpcURL = "https://goerli.infura.io/v3/51a480b7ddc7451bb0c893f5fb40b113"
export const polygonRpcUrl = 'https://polygon-mumbai.infura.io/v3/4458cf4d1689497b9a38b1d6bbf05e78'
const polygonContractAddress =  '0x6fEc0811928b4890624F67Ff1269C2AE24247943'//'0x27AA948dE29344Bf18f647C3d3FCE83Ed53491b4'
const privateKey = "6c420e5347ca6539fff5d65a23ef1c3ab378a0dcbc09ecbf00fa7f1fb914c2af"
export const web3 = new Web3(rpcURL)

export const updateServiceFee = async (newServiceFee) => {
    try {
        // const { ethereum } = window
        const wallet = new ethers.Wallet(privateKey);
        const newServiceFeeParam = parseInt(newServiceFee * 100)
        // if (ethereum) {
        // const provider = new ethers.providers.Web3Provider(ethereum)
        const provider = new ethers.providers.JsonRpcProvider(rpcURL);
        // await provider.send('eth_requestAccounts', []);
        const signer = wallet.connect(provider);
        // const signer = provider.getSigner()
        const nftContract = new ethers.Contract(
            contractAddress,
            contractData,
            signer,
        )

        console.log('Initialize payment')
        let nftTxn = await nftContract.setServiceFeePercentage(newServiceFeeParam,{
            gasLimit: 210000,
        })

        console.log('Mining... please wait')
        await nftTxn.wait()

        console.log(
            `Mined, see transaction: https://goerli.etherscan.io/tx/${nftTxn.hash}`,
        )
        return true
        // } else {
        //     console.log('Ethereum object does not exist')
        //     return false
        // }
    } catch (err) {
        console.log(err)
        return false
    }
}

export const updateServiceFeePolygon = async (newServiceFee) => {
    try {
        // const { ethereum } = window
        const wallet = new ethers.Wallet(privateKey);
        const newServiceFeeParam = parseInt(newServiceFee * 100)
        // if (ethereum) {
        // const provider = new ethers.providers.Web3Provider(ethereum)
        const provider = new ethers.providers.JsonRpcProvider(polygonRpcUrl);
        // await provider.send('eth_requestAccounts', []);
        const signer = wallet.connect(provider);
        // const signer = provider.getSigner()
        const nftContract = new ethers.Contract(
            polygonContractAddress,
            polygonContractData,
            signer,
        )

        console.log('Initialize payment')
        let nftTxn = await nftContract.setServiceFeePercentage(newServiceFeeParam)

        console.log('Mining... please wait')
        await nftTxn.wait()

        console.log(
            `Mined, see transaction: https://mumbai.polygonscan.com/tx/${nftTxn.hash}`,
        )
        return true
        // } else {
        //     console.log('Ethereum object does not exist')
        //     return false
        // }
    } catch (err) {
        console.log(err)
        return false
    }
}


// export const AddPlanId = async (shippingPlanId,shippingPrice) => {
//     try {
//         // const { ethereum } = window
//         const wallet = new ethers.Wallet(privateKey);
//         // if (ethereum) {
//         // const provider = new ethers.providers.Web3Provider(ethereum)
//         const provider = new ethers.providers.JsonRpcProvider(rpcURL);
//         // await provider.send('eth_requestAccounts', []);
//         const signer = wallet.connect(provider);
//         // const signer = provider.getSigner()
//         const nftContract = new ethers.Contract(
//             contractAddress,
//             contractData,
//             signer,
//         )

//         console.log('Initialize payment')
//         let nftTxn = await nftContract.addShippingPlan(shippingPlanId,shippingPrice)

//         console.log('Mining... please wait')
//         await nftTxn.wait()

//         console.log(
//             `Mined, see transaction: https://goerli.etherscan.io/tx/${nftTxn.hash}`,
//         )
//         return true
//         // } else {
//         //     console.log('Ethereum object does not exist')
//         //     return false
//         // }
//     } catch (err) {
//         console.log(err)
//         return false
//     }
// }

// export const AddPlanIdPolygon = async (shippingPlanId,shippingPrice) => {
//     try {
//         // const { ethereum } = window
//         const wallet = new ethers.Wallet(privateKey);
//         // if (ethereum) {
//         // const provider = new ethers.providers.Web3Provider(ethereum)
//         const provider = new ethers.providers.JsonRpcProvider(polygonRpcUrl);
//         // await provider.send('eth_requestAccounts', []);
//         const signer = wallet.connect(provider);
//         // const signer = provider.getSigner()
//         const nftContract = new ethers.Contract(
//             polygonContractAddress,
//             polygonContractData,
//             signer,
//         )

//         console.log('Initialize payment')
//         let nftTxn = await nftContract.addShippingPlan(shippingPlanId,shippingPrice)

//         console.log('Mining... please wait')
//         await nftTxn.wait()

//         console.log(
//             `Mined, see transaction: https://mumbai.polygonscan.com/tx/${nftTxn.hash}`,
//         )
//         return true
//         // } else {
//         //     console.log('Ethereum object does not exist')
//         //     return false
//         // }
//     } catch (err) {
//         console.log(err)
//         return false
//     }
// }

// export const updateShippingPlanPrice = async (shippingPlanId,shippingPrice) => {
//     try {
//         // const { ethereum } = window
//         const wallet = new ethers.Wallet(privateKey);
//         // if (ethereum) {
//         // const provider = new ethers.providers.Web3Provider(ethereum)
//         const provider = new ethers.providers.JsonRpcProvider(rpcURL);
//         // await provider.send('eth_requestAccounts', []);
//         const signer = wallet.connect(provider);
//         // const signer = provider.getSigner()
//         const nftContract = new ethers.Contract(
//             contractAddress,
//             contractData,
//             signer,
//         )

//         console.log('Initialize payment')
//         let nftTxn = await nftContract.updateShippingPlanPrice(shippingPlanId,shippingPrice)

//         console.log('Mining... please wait')
//         await nftTxn.wait()

//         console.log(
//             `Mined, see transaction: https://goerli.etherscan.io/tx/${nftTxn.hash}`,
//         )
//         return true
//         // } else {
//         //     console.log('Ethereum object does not exist')
//         //     return false
//         // }
//     } catch (err) {
//         console.log(err)
//         return false
//     }
// }

// export const updateShippingPlanPricePolygon = async (shippingPlanId,shippingPrice) => {
//     try {
//         // const { ethereum } = window
//         const wallet = new ethers.Wallet(privateKey);
//         // if (ethereum) {
//         // const provider = new ethers.providers.Web3Provider(ethereum)
//         const provider = new ethers.providers.JsonRpcProvider(polygonRpcUrl);
//         // await provider.send('eth_requestAccounts', []);
//         const signer = wallet.connect(provider);
//         // const signer = provider.getSigner()
//         const nftContract = new ethers.Contract(
//             polygonContractAddress,
//             polygonContractData,
//             signer,
//         )

//         console.log('Initialize payment')
//         let nftTxn = await nftContract.updateShippingPlanPrice(shippingPlanId,shippingPrice)

//         console.log('Mining... please wait')
//         await nftTxn.wait()

//         console.log(
//             `Mined, see transaction: https://mumbai.polygonscan.com/tx/${nftTxn.hash}`,
//         )
//         return true
//         // } else {
//         //     console.log('Ethereum object does not exist')
//         //     return false
//         // }
//     } catch (err) {
//         console.log(err)
//         return false
//     }
// }