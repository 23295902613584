import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import styles from './TwoFa.module.sass';
import Item from '../Item';
import TextInput from '../../../components/TextInput';
import { ThreeDots } from 'react-loader-spinner';
import { GoShieldCheck } from 'react-icons/go';
import {
  notifySuccess,
  notifyError,
} from '../../../apiConnection/notification.api';
import EnableTwoFactor from './EnableTwoFactor';
import DisableTwoFactor from './DisableTwoFactor';
import { getRequest } from '../../../apiConnection/api';
import { twoFactorSetup,twoFactorVerification } from '../../../environment';
import axios from 'axios';


const TwoFa = ({ className }) => {
  const [user, setUser] = useState({});
  const [isTwoFaEnabled, setIsTwoFaEnabled] = useState(false);
  const [twoFaBase, setTwoFaBase] = useState('');
  const [show, setShow] = useState(false);
  const [showDisableModal, setShowDisableModal] = useState(false);
  
  const handleSubmit = async () => {
    try {
      // setIsLoading(true);
      const response = await getRequest(twoFactorSetup);
      if (response && response.data) {
        setTwoFaBase(response?.data);
      }
    } catch (error) {
      notifyError('Email or password is incorrect');
    }
  };

  useEffect(() => {
    const userInfo = JSON.parse(localStorage.getItem('user'));
    if (userInfo) {
      setUser(userInfo);
      setIsTwoFaEnabled(userInfo?.is2faEnabled)
    }
  }, []);

  useEffect(() => {
    const userInfo = JSON.parse(localStorage.getItem('user'));
    if (userInfo) {
      setUser(userInfo);
    }
  }, [isTwoFaEnabled]);

  return (
    <Item
      className={cn(styles.card, className)}
      title='Two Factor Authentication'
      classTitle='title-purple'
    >
      {!isTwoFaEnabled && (
        <div className={styles.file}>
          <button
            className={cn('button', styles.button)}
            type='button'
            onClick={() => {
              setShow(true);
              handleSubmit();
            }}
          >
            <GoShieldCheck size='24' />
            <span>Setup 2FA</span>
          </button>
        </div>
      )}
      {isTwoFaEnabled && (
        <div className={styles.file}>
          <button
            className={'btn btn-success'}
            type='button'
            style={{ borderRadius: '12px', height: 48 }}
            onClick={() => {
              setShowDisableModal(true)
            }}
          >
            <GoShieldCheck size='24' />
            <span>Disable 2FA</span>
          </button>
        </div>
      )}
      {/* <div className={styles.fieldset}>
        <TextInput
          className={styles.field}
          label='Old password'
          name='oldPassword'
          type='password'
          tooltip='Maximum 100 characters. No HTML or emoji allowed'
          required
          value={values.oldPassword}
          onChange={handleChange}
          onBlur={handleBlur}
          isError={touched.oldPassword && !!errors.oldPassword}
          errorMessage={errors.oldPassword}
        />
        <div className={styles.row}>
          <TextInput
            className={styles.field}
            label='New password'
            name='newPassword'
            type='password'
            tooltip='Maximum 100 characters. No HTML or emoji allowed'
            required
            value={values.newPassword}
            onChange={handleChange}
            onBlur={handleBlur}
            isError={touched.newPassword && !!errors.newPassword}
            errorMessage={errors.newPassword}
          />
          <TextInput
            className={styles.field}
            label='Confirm new password'
            name='confirmPassword'
            type='password'
            tooltip='Maximum 100 characters. No HTML or emoji allowed'
            required
            value={values.confirmPassword}
            onChange={handleChange}
            onBlur={handleBlur}
            isError={touched.confirmPassword && !!errors.confirmPassword}
            errorMessage={errors.confirmPassword}
          />
        </div>
        <button
          onClick={handleSubmit}
          disabled={!dirty || disableForm || !isValid || loading}
          className={cn('button-stroke', styles.button)}
        >
          {loading ? (
            <ThreeDots
              height='30'
              width='30'
              radius='9'
              color='#2A85FF'
              ariaLabel='three-dots-loading'
              wrapperStyle={{}}
              wrapperClassName=''
              visible={true}
            />
          ) : (
            'Update password'
          )}
        </button>
      </div> */}
      <EnableTwoFactor 
      show={show} 
      setShow={setShow}
      twoFaBase={twoFaBase} 
      setIsTwoFaEnabled={setIsTwoFaEnabled}
      />
      <DisableTwoFactor 
      showDisableModal={showDisableModal} 
      setShowDisableModal={setShowDisableModal}
      setIsTwoFaEnabled={setIsTwoFaEnabled}
      />
    </Item>
  );
};

export default TwoFa;
