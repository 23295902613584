import React, { useEffect, useState } from 'react';
import axios from 'axios';
import moment from 'moment';
import DataTable from 'react-data-table-component';
import styles from './reportsModule.module.sass';
import TooltipGlodal from '../../components/TooltipGlodal';
import { FaEye, FaArrowRotateRight, FaBan } from 'react-icons/fa6';
import ViewReport from './ViewReport';
import RecoverOrganization from './RecoverOrganization';
import { customStyles } from '../../helpers/commonStyles';
import { reportsListing } from '../../environment';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import UserReport from './UserReports';
import OrganizationReport from './OrganizationReport';
import NftReport from './NftReport';

const ReportsModule = () => {
  const [users, setUsers] = useState('');
  const [deleteId, setDeleteId] = useState('');
  const [reportDetail, setReportDetail] = useState('');
  const [actionType, setActionType] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const [modalMessage, setModalMessage] = useState('');
  const [show, setShow] = useState(false);
  const [isRecoverShow, setIsRecoverShow] = useState(false);

  const getReportsListing = async () => {
    const reports = await axios.get(reportsListing, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
    if (reports?.data) {
      setUsers(reports?.data?.data);
    }
  };
  useEffect(() => {
    getReportsListing();
  }, []);

  const columns = [
    {
      name: 'Reporter Name',
      selector: (row) => `${row.firstName} ${row.lastName}`,
    },
    {
      name: 'Reporter Email',
      selector: (row) => row?.email,
    },
    {
      name: 'Reported Organization',
      selector: (row) => row?.organizationId?.nonProfitName,
    },
    {
      name: 'Reported Date',
      selector: (row) => moment(row.createdAt).format('MMMM, DD, YYYY'),
    },
    {
      name: 'Actions',
      cell: (row) => (
        <>
          <button
            className={styles.viewButton}
            onClick={() => {
              setDeleteId(row?._id);
              setReportDetail(row);
              setShow(true);
            }}
          >
            <FaEye />
          </button>
          &nbsp;&nbsp;
          {row?.isBan === true ? (
            <button
              className={styles.recoverButton}
              onClick={() => {
                setDeleteId(row?.organizationId?._id);
                setIsRecoverShow(true);
                setActionType('recover');
                setModalTitle('Recovery Alert');
                setModalMessage(
                  'Are you sure you want to recover this organization ?'
                );
              }}
            >
              <FaArrowRotateRight />
            </button>
          ) : (
            <button
              className={styles.banButton}
              onClick={() => {
                setDeleteId(row?.organizationId?._id);
                setIsRecoverShow(true);
                setActionType('ban');
                setModalTitle('Ban Alert');
                setModalMessage(
                  'Are you sure you want to ban this organization ?'
                );
              }}
            >
              <FaBan />
            </button>
          )}
          {/* &nbsp;&nbsp; */}
        </>
      ),
    },
  ];

  const data = users;

  return (
    <>
     <Tabs
      defaultActiveKey='users'
      id='uncontrolled-tab-example'
      className='mb-3'
    >
      <Tab eventKey='users' title='Users'>
        <div className={styles.outer}>
          <UserReport />
        </div>
      </Tab>
      <Tab eventKey='organization' title='Organization'>
        <div className={styles.outer}>
          <OrganizationReport />
        </div>
      </Tab>
      <Tab eventKey='nft' title='NFT'>
        <div className={styles.outer}>
          <NftReport />
        </div>
      </Tab>
    </Tabs>




    {/* previous table */}
      {/* <div className={styles.settings}>
        <div className={styles.btnOuter}>
          <h3>Reports</h3>
        </div>

        <DataTable
          columns={columns}
          data={data}
          pagination
          customStyles={customStyles}
        />
      </div>
      <ViewReport
        show={show}
        id={deleteId}
        setShow={setShow}
        getReportsListing={getReportsListing}
        reportDetail={reportDetail}
      />

      <RecoverOrganization
        show={isRecoverShow}
        id={deleteId}
        setShow={setIsRecoverShow}
        actionType={actionType}
        modalTitle={modalTitle}
        modalMessage={modalMessage}
        getReportsListing={getReportsListing}
      />
      <TooltipGlodal /> */}
    </>
  );
};

export default ReportsModule;
