import { updateUserProfile, updatePassword } from '../environment';
import { getRequest, postRequest } from '../apiConnection/api';
import { notifyError, notifySuccess } from '../apiConnection/notification.api';
import { useMutation, useQueryClient } from 'react-query';

export function useEditProfile() {
  const fn = (values) => {
    return postRequest(updateUserProfile, values, {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    });
  };
  const queryClient = useQueryClient();
  return useMutation(fn, {
    onSuccess: (data) => {
      if (data.code === 200) {
        queryClient.invalidateQueries('Get User Addresses');
        notifySuccess(data.status);
      }
    },
    onError: (error) => {
      notifyError('There was a problem while updating information ' + error);
    },
  });
}

export function useUpdatePassword() {
  const fn = (values) => {
    return postRequest(updatePassword, values, {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    });
  };
  const queryClient = useQueryClient();
  return useMutation(fn, {
    onSuccess: (data) => {
      if (data.code === 200) {
        queryClient.invalidateQueries('Update Password');
        notifySuccess(data.status);
      }
    },
    onError: (error) => {
      notifyError('There was a problem while updating information ' + error);
    },
  });
}
