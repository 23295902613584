import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import cn from 'classnames';
import styles from './SignIn.module.sass';
import { use100vh } from 'react-div-100vh';
import { Link } from 'react-router-dom';
import { ThreeDots } from 'react-loader-spinner';
import TextInput from '../../components/TextInput';
import Image from '../../components/Image';
import { postRequest } from '../../apiConnection/api';
import { signIn } from '../../environment';
import {
  notifySuccess,
  notifyError,
} from '../../apiConnection/notification.api';
import VerifyTwoFactor from './VerifyTwoFactor';

const SignIn = () => {
  const history = useHistory();
  const location = useLocation();

  const heightWindow = use100vh();
  const [show, setShow] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setIsLoading] = useState(false);
  const handleChangeEmail = (value) => {
    setEmail(value);
  };
  const handleChangePassword = (value) => {
    setPassword(value);
  };
  const state = location.state;
  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      const response = await postRequest(signIn, {
        email,
        password,
        role:"admin"
      });

      console.log('response :', response);
      if (response && response.data) {
        if (response.data?.is2faEnabled === true) {
          localStorage.setItem('token', response.data?.token);
          localStorage.setItem('user', JSON.stringify(response.data));
          setShow(true);
          setIsLoading(false);
        } else {
          setTimeout(() => {
            setIsLoading(false);
            localStorage.setItem('token', response.data?.token);
            localStorage.setItem('user', JSON.stringify(response.data));
            notifySuccess(response?.message);
            history.push('/');
          }, 1000);
        }
      }
    } catch (error) {
      notifyError('Email or password is incorrect');
      setIsLoading(false);
    }
  };
  return (
    <div className={styles.login} style={{ minHeight: heightWindow }}>
      <div className={styles.wrapper}>
        {/* <Link className={styles.logo} to='/'>
          <Image
            className={styles.pic}
            src='/images/content/logo.svg'
            srcDark='/images/content/logoDarkMode.svg'
            alt='Core'
          />
        </Link> */}
        <div className={cn('h4', styles.title)}>Admin Sign in</div>
        {/* <div className={styles.head}>
          <div className={styles.subtitle}>Sign up with Open account</div>
          <div className={styles.btns}>
            <button className={cn('button-stroke', styles.button)}>
              <img src='/images/content/google.svg' alt='Google' />
              Google
            </button>
            <button className={cn('button-stroke', styles.button)}>
              <Image
                className={styles.pic}
                src='/images/content/apple-dark.svg'
                srcDark='/images/content/apple-light.svg'
                alt='Apple'
              />
              Apple ID
            </button>
          </div>
        </div> */}
        <div className={styles.body}>
          {/* <div className={styles.subtitle}>Or continue with email address</div> */}
          <TextInput
            className={styles.field}
            name='email'
            type='email'
            placeholder='Your email'
            required
            icon='mail'
            handleChange={handleChangeEmail}
          />
          <TextInput
            className={styles.field}
            name='password'
            type='password'
            placeholder='Password'
            required
            icon='lock'
            handleChange={handleChangePassword}
          />
          <div className={styles.info}>
            {/* Forget Password ? */}
            <Link className={styles.link} to='/forget-password'>
              Forgot Password?
            </Link>
          </div>
          <button
            onClick={() => handleSubmit()}
            className={cn('button', styles.button)}
            disabled={loading}
          >
            {loading ? (
              <ThreeDots
                height='30'
                width='30'
                radius='9'
                color='#4fa94d'
                ariaLabel='three-dots-loading'
                wrapperStyle={{}}
                wrapperClassName=''
                visible={true}
              />
            ) : (
              'Sign in'
            )}
          </button>
          {/* <div className={styles.note}>
            This site is protected by reCAPTCHA and the Google Privacy Policy.
          </div> */}
          {/* <div className={styles.info}>
            Don’t have an account?{" "}
            <Link className={styles.link} to="/sign-up">
              Sign up
            </Link>
          </div> */}
        </div>
      </div>
      <VerifyTwoFactor show={show} setShow={setShow} />
    </div>
  );
};

export default SignIn;
