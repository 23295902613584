import React, { useEffect, useMemo, useState } from "react";
import styles from "./Shippings.module.sass";
import Overview from "../../components/Overview";
import { useGetShippings } from "../../hooks/nftHooks";

let overviewNav = [
    [
        {
            title: "Shippings",
            counter: 0,
            icon: "/images/content/shipping.svg",
            color: "#B1E5FC",
            value: -37.8,
        },
        {
            title: "Amount spent on shippings",
            counter: 0,
            color: "#CABDFF",
            value: 37.8,
        }
    ]
];

let categoriesNav = [
    [
        {
            title: "Completed",
            counter: 0,
            icon: "/images/content/success.svg",
            color: "#B1E5FC",
            value: -37.8,
        },
        {
            title: "Returned",
            counter: 0,
            icon: "/images/content/cancel.svg",
            color: "#CABDFF",
            value: 37.8,
        }
    ],
    [
        {
            title: "To be delivered",
            counter: 0,
            icon: "/images/content/toBeDelivered.svg",
            color: "#B1E5FC",
            value: -37.8,
        },
        {
            title: "To be posted",
            counter: 0,
            icon: "/images/content/toBePosted.svg",
            color: "#CABDFF",
            value: 37.8,
        },
    ]
];

const Shippings = () => {
    const [shippingsData, setShippingsData] = useState([])
    const [days, setDays] = useState('');
    const { refetch } = useGetShippings(days, setShippingsData)

    useEffect(() => {
        refetch()
    }, [days])

    useMemo(() => {
        overviewNav = [
            [
                {
                    title: "Shippings",
                    counter: shippingsData.totalShippings,
                    icon: "/images/content/shipping.svg",
                    color: "#B1E5FC",
                    value: -37.8,
                },
                {
                    title: "Amount spent on shippings",
                    counter: shippingsData.amountSpentOnShippings ? '£' + shippingsData.amountSpentOnShippings : null,
                    color: "#CABDFF",
                    value: 37.8,
                }
            ]
        ];

        categoriesNav = [
            [
                {
                    title: "Completed",
                    counter: shippingsData.delivered,
                    icon: "/images/content/success.svg",
                    color: "#B1E5FC",
                    value: -37.8,
                },
                {
                    title: "Returned",
                    counter: shippingsData.returned,
                    icon: "/images/content/cancel.svg",
                    color: "#CABDFF",
                    value: 37.8,
                }
            ],
            [
                {
                    title: "To be delivered",
                    counter: shippingsData.toBeDelivered,
                    icon: "/images/content/toBeDelivered.svg",
                    color: "#B1E5FC",
                    value: -37.8,
                },
                {
                    title: "To be posted",
                    counter: shippingsData.toBePosted,
                    icon: "/images/content/toBePosted.svg",
                    color: "#CABDFF",
                    value: 37.8,
                },
            ]
        ]
    }, [shippingsData])

    return (
        <>
            <div className={styles.row}>
                <div className={styles.col}>
                    <Overview className={styles.card} nav={overviewNav} title="Overview" classTitle="title-red" setDays={setDays} />
                </div>
            </div>
            <div className={styles.row}>
                <div className={styles.col}>
                    <Overview className={styles.card} nav={categoriesNav} title="Shipping Type" classTitle="title-purple" />
                </div>
            </div>
        </>
    );
};

export default Shippings;