import React, { useState, useEffect } from 'react';
import axios from 'axios';
import cn from 'classnames';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import { ThreeDots } from 'react-loader-spinner';
import TooltipGlodal from '../../components/TooltipGlodal';
import styles from './Roles.module.sass';
import TextInput from '../../components/TextInput';
import { RoleSchema } from './RolesSchema';
import { createRole, permissionListing } from '../../environment';
import {
  notifySuccess,
  notifyError,
} from '../../apiConnection/notification.api';

const CreateRole = () => {
  const history = useHistory();
  const [loading, setIsLoading] = useState(false);
  const [allPermissions, setAllPermissions] = useState([]);
  const [checkboxItems, setCheckboxItems] = useState([]);

  const getPermissionListing = async () => {
    const getPermissions = await axios.get(permissionListing, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });

    if (getPermissions?.data?.data?.length) {
      setAllPermissions(getPermissions?.data?.data);
    }
  };
  useEffect(() => {
    getPermissionListing();
  }, []);
  const {
    dirty,
    values,
    handleChange,
    handleSubmit,
    handleBlur,
    touched,
    errors,
    resetForm,
    isValid,
  } = useFormik({
    onSubmit: async (values, { setErrors }) => {
      setIsLoading(true);

      try {
        const saveData = await axios.post(
          createRole,
          {
            roleName: values.roleName,
            permissions: checkboxItems,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          }
        );

        if (saveData) {
          setErrors({});
          setIsLoading(false);
          notifySuccess(saveData?.data?.message);
          resetForm();
          history.push('/roles');
        }
      } catch (error) {
        console.log('error :', error);
        notifyError(error?.response?.data?.message);
        setIsLoading(false);
      }
    },
    initialValues: {
      roleName: '',
    },
    validationSchema: RoleSchema,
    validateOnBlur: true,
    validateOnMount: false,
    enableReinitialize: true,
  });

  const handleChangeCheckbox = (e) => {
    const value = e.target.value;
    setCheckboxItems((prevItems) => {
      if (prevItems.includes(value)) {
        return prevItems.filter((item) => item !== value);
      } else {
        return [...prevItems, value];
      }
    });
  };

  console.log('checkboxItems :', checkboxItems);
  return (
    <>
      <div className={styles.settings}>
        <div className={styles.fieldset}>
          <TextInput
            className={styles.field}
            label='Role'
            name='roleName'
            type='text'
            tooltip='Required.'
            placeholder='Enter role name'
            required
            value={values.roleName}
            onChange={handleChange}
            onBlur={handleBlur}
            isError={touched.roleName && !!errors.roleName}
            errorMessage={errors.roleName}
          />

          <div className={styles.row}>
            {allPermissions?.map((permission) => (
              <div className={styles.columns}>
                <input
                  type='checkbox'
                  style={{ marginRight: '2px' }}
                  key={`generalPermission+${permission?.permissionName}`}
                  size='md'
                  onChange={handleChangeCheckbox}
                  value={permission?.permissionName}
                  id='generalPermission'
                  name={`generalPermission+${permission?.permissionName}`}
                  required
                  isChecked={checkboxItems.includes(permission?.permissionName)}
                />
                <label for='scales'>{permission?.permissionName}</label>
              </div>
            ))}
          </div>
        </div>
        <div className={styles.buttonsParent}>
          <button
            className={cn('button', styles.button)}
            style={{ marginTop: 20 }}
            onClick={() => history.push('/roles')}
          >
            Back
          </button>
          <button
            onClick={handleSubmit}
            disabled={!dirty || !isValid || loading || !checkboxItems?.length}
            className={cn('button', styles.button)}
            style={{ marginTop: 20 }}
          >
            {loading ? (
              <ThreeDots
                height='30'
                width='30'
                radius='9'
                color='#2A85FF'
                ariaLabel='three-dots-loading'
                wrapperStyle={{}}
                wrapperClassName=''
                visible={true}
              />
            ) : (
              'Save'
            )}
          </button>
        </div>
      </div>
      <TooltipGlodal />
    </>
  );
};

export default CreateRole;
