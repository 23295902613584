import React, { useState } from 'react';
import styles from './Home.module.sass';
import TooltipGlodal from '../../components/TooltipGlodal';
import Overview from './Overview';

const Home = () => {
  return (
    <>
      <div className={styles.row}>
        <div className={styles.col}>
          <Overview className={styles.card} title='Dashboard' />
          {/* <ProductViews className={styles.card} />
          <ProTips className={styles.card} />
          <MoreCustomers /> */}
        </div>
      </div>
      <TooltipGlodal />
    </>
  );
};

export default Home;
