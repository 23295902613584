import React, { useEffect, useMemo, useState } from "react";
import styles from "./Transactions.module.sass";
import Overview from "../../components/Overview";
import { useGetNTransactions } from "../../hooks/nftHooks";

let overviewNav = [
    [
        {
            title: "Transactions",
            counter: 0,
            icon: "/images/content/bankNote2.svg",
            color: "#B1E5FC",
            value: -37.8,
        },
        {
            title: "Amount transacted",
            counter: 0,
            // icon: "/images/content/bag.svg",
            color: "#CABDFF",
            value: 37.8,
        }
    ]
];

let categoriesNav = [
    [
        {
            title: "Completed transactions",
            counter: 0,
            icon: "/images/content/success.svg",
            color: "#B1E5FC",
            value: -37.8,
        },
        {
            title: "Amount completed transactions",
            counter: 0,
            color: "#CABDFF",
            value: 37.8,
        }
    ],
    [
        {
            title: "Transactions in escrow",
            counter: 0,
            icon: "/images/content/note.svg",
            color: "#B1E5FC",
            value: -37.8,
        },
        {
            title: "Amount transactions in escrow",
            counter: 0,
            color: "#CABDFF",
            value: 37.8,
        },
    ],
    [
        {
            title: "Cancelled transactions",
            counter: 0,
            icon: "/images/content/cancel.svg",
            color: "#B1E5FC",
            value: -37.8,
        },
        {
            title: "Amount cancelled transactions",
            counter: 0,
            color: "#CABDFF",
            value: 37.8,
        },
    ]
];

const Transactions = () => {
    const [transactionsData, setTransactionsData] = useState([])
    const [days, setDays] = useState('');
    const { refetch } = useGetNTransactions(days, setTransactionsData)

    useEffect(() => {
        refetch()
    }, [days])

    useMemo(() => {
        overviewNav = [
            [
                {
                    title: "Transactions",
                    counter: transactionsData.transactionsTotal,
                    icon: "/images/content/bankNote2.svg",
                    color: "#B1E5FC",
                    value: -37.8,
                },
                {
                    title: "Amount transacted",
                    counter: transactionsData.transactionsAmountTotal !== undefined ? '£' + (transactionsData.transactionsAmountTotal)?.toFixed(2) : null,
                    color: "#CABDFF",
                    value: 37.8,
                }
            ]
        ];

        categoriesNav = [
            [
                {
                    title: "Completed transactions",
                    counter: transactionsData.completedTransactionsCount,
                    icon: "/images/content/success.svg",
                    color: "#B1E5FC",
                    value: -37.8,
                },
                {
                    title: "Amount completed transactions",
                    counter: transactionsData.completedTransactionsAmount !== undefined ? '£' + (transactionsData.completedTransactionsAmount)?.toFixed(2) : null,
                    color: "#CABDFF",
                    value: 37.8,
                }
            ],
            [
                {
                    title: "Transactions in escrow",
                    counter: transactionsData.escrowTransactionsTotal,
                    icon: "/images/content/note.svg",
                    color: "#B1E5FC",
                    value: -37.8,
                },
                {
                    title: "Amount transactions in escrow",
                    counter: transactionsData.escrowTransactionsAmountTotal !== undefined ? '£' + (transactionsData.escrowTransactionsAmountTotal)?.toFixed(2) : null,
                    color: "#CABDFF",
                    value: 37.8,
                },
            ],
            [
                {
                    title: "Cancelled transactions",
                    counter: transactionsData.cancelledTransactionsCount,
                    icon: "/images/content/cancel.svg",
                    color: "#B1E5FC",
                    value: -37.8,
                },
                {
                    title: "Amount cancelled transactions",
                    counter: transactionsData.cancelledTransactionsAmount !== undefined ? '£' + (transactionsData.cancelledTransactionsAmount)?.toFixed(2) : null,
                    color: "#CABDFF",
                    value: 37.8,
                },
            ]
        ]
    }, [transactionsData])

    return (
        <>
            <div className={styles.row}>
                <div className={styles.col}>
                    <Overview className={styles.card} nav={overviewNav} title="Overview" classTitle="title-red" setDays={setDays} />
                </div>
            </div>
            <div className={styles.row}>
                <div className={styles.col}>
                    <Overview className={styles.card} nav={categoriesNav} title="Transaction Type" classTitle="title-purple" />
                </div>
            </div>
        </>
    );
};

export default Transactions;