import React, { useState, useEffect } from 'react';
import axios from 'axios';
import cn from 'classnames';
import { useFormik } from 'formik';
import { useHistory, useLocation } from 'react-router-dom';
import { ThreeDots } from 'react-loader-spinner';

import TooltipGlodal from '../../components/TooltipGlodal';
import styles from './Roles.module.sass';
import TextInput from '../../components/TextInput';
import { RoleSchema } from './RolesSchema';
import { updateRole, permissionListing } from '../../environment';
import {
  notifySuccess,
  notifyError,
} from '../../apiConnection/notification.api';

const EditRole = () => {
  const history = useHistory();
  const location = useLocation();
  const [loading, setIsLoading] = useState(false);
  const [allPermissions, setAllPermissions] = useState([]);
  const [checkboxItems, setCheckboxItems] = useState(
    location?.state?.row?.permissions ?? []
  );

  const getPermissionListing = async () => {
    const getPermissions = await axios.get(permissionListing, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });

    if (getPermissions?.data?.data?.length) {
      setAllPermissions(getPermissions?.data?.data);
    }
  };

  useEffect(() => {
    getPermissionListing();
  }, []);

  const {
    dirty,
    values,
    handleChange,
    handleSubmit,
    handleBlur,
    touched,
    errors,
    resetForm,
    isValid,
  } = useFormik({
    onSubmit: async (values, { setErrors }) => {
      setIsLoading(true);

      try {
        const updateData = await axios.put(
          updateRole,
          {
            roleName: values.roleName,
            permissions: checkboxItems,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          }
        );
        if (updateData) {
          setErrors({});
          setIsLoading(false);
          notifySuccess(updateData?.data?.message);
          resetForm();
          history.push('/roles');
        }
      } catch (error) {
        console.log('error :', error);
        notifyError(error?.response?.data?.message);
        setIsLoading(false);
      }
    },
    initialValues: {
      roleName: location?.state?.row?.roleName,
    },
    validationSchema: RoleSchema,
    validateOnBlur: true,
    validateOnMount: false,
    enableReinitialize: true,
  });

  const onPermissionCheckboxChange = (e) => {
    const value = e.target.value;
    setCheckboxItems((prev) =>
      checkboxItems.includes(value)
        ? prev.filter((cur) => cur !== value)
        : [...prev, value]
    );
  };

  return (
    <>
      <div className={styles.settings}>
        <div className={styles.fieldset}>
          <TextInput
            className={styles.field}
            label='Role'
            name='roleName'
            type='text'
            tooltip='Required.'
            placeholder='Enter role name'
            required
            value={values.roleName}
            onChange={handleChange}
            onBlur={handleBlur}
            isError={touched.roleName && !!errors.roleName}
            errorMessage={errors.roleName}
          />

          <div className={styles.row}>
            {allPermissions?.map((permission) => (
              <div className={styles.columns} key={permission?._id}>
                <input
                  type='checkbox'
                  name='permission'
                  id={permission?._id}
                  value={permission.permissionName}
                  style={{ marginRight: '2px' }}
                  checked={checkboxItems.includes(permission.permissionName)}
                  onChange={onPermissionCheckboxChange}
                />
                <label htmlFor={permission?._id}>
                  {permission.permissionName}
                </label>
              </div>
            ))}
          </div>
        </div>
        <div className={styles.buttonsParent}>
          <button
            className={cn('button', styles.button)}
            style={{ marginTop: 20 }}
            onClick={() => history.push('/roles')}
          >
            Back
          </button>
          <button
            onClick={handleSubmit}
            disabled={!dirty || !isValid || loading || !checkboxItems?.length}
            className={cn('button', styles.button)}
            style={{ marginTop: 20 }}
          >
            {loading ? (
              <ThreeDots
                height='30'
                width='30'
                radius='9'
                color='#2A85FF'
                ariaLabel='three-dots-loading'
                wrapperStyle={{}}
                wrapperClassName=''
                visible={true}
              />
            ) : (
              'Save'
            )}
          </button>
        </div>
      </div>
      <TooltipGlodal />
    </>
  );
};

export default EditRole;
