export function getRequest(url) {
  return fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  }).then(checkHTTPErrors);
}

export function postRequest(url, body, headers) {
  if (!headers) {
    headers = {};
  }
  headers['Content-Type'] = 'application/json';
  return fetch(url, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(body),
  }).then(checkHTTPErrors);
}

async function checkHTTPErrors(response) {
  if (!response.ok) {
    if (response.status === 401) {
      // window.location.replace("http://127.0.0.1:3002/sign-in");
      window.location.pathname = '/sign-in';
      throw new Error('Authentication Error');
    }
    const error = await response.json();
    if (response.status === 400) throw new Error(error.error);
    throw new Error(error.Reason || error.error || response.statusText);
  }
  return response.json();
}
