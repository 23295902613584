import React from "react";
import styles from "./reports.module.sass";
import FiscalSponsorshipLettersModule from "./FiscalSponsorshipLetters";

const FiscalSponsorshipLetters = () => {
  return (
    <div className={styles.outer}>
      <FiscalSponsorshipLettersModule />
    </div>
  );
};

export default FiscalSponsorshipLetters;
