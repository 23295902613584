import axios from 'axios';
import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { destroyRole } from '../../environment';
import {
  notifySuccess,
  notifyError,
} from '../../apiConnection/notification.api';

const ViewReport = ({ id, show, setShow, getRoleListing, reportDetail }) => {
  const handleClose = () => {
    setShow(false);
  };

  // const banOrganization = async () => {
  //   try {
  //     const delRole = await axios.delete(`${destroyRole}/${id}`, {
  //       headers: {
  //         Authorization: `Bearer ${localStorage.getItem('token')}`,
  //       },
  //     });

  //     if (delRole?.data) {
  //       handleClose();
  //       notifySuccess(delRole?.data?.message);
  //       getRoleListing();
  //     }
  //   } catch (error) {
  //     console.log('error :', error);
  //     handleClose();
  //     notifyError(error?.response?.data?.message);
  //   }
  // };

  // const rejectRequest = async () => {
  //   try {
  //     const delRole = await axios.delete(`${destroyRole}/${id}`, {
  //       headers: {
  //         Authorization: `Bearer ${localStorage.getItem('token')}`,
  //       },
  //     });

  //     if (delRole?.data) {
  //       handleClose();
  //       notifySuccess(delRole?.data?.message);
  //       getRoleListing();
  //     }
  //   } catch (error) {
  //     console.log('error :', error);
  //     handleClose();
  //     notifyError(error?.response?.data?.message);
  //   }
  // };

  return (
    <div>
      <Modal show={show} onHide={handleClose} size='lg'>
        <Modal.Header closeButton>
          <h3>Report Detail</h3>
        </Modal.Header>
        <Modal.Body>
          <h5>Reporter Name:</h5>
          {`${reportDetail?.firstName} ${reportDetail?.lastName}`} <br />
          <br />
          <h5>Organization Name:</h5>
          {`${reportDetail?.organizationId?.nonProfitName}`} <br />
          <br />
          <h5>Report Reason:</h5>
          {`${reportDetail?.reason}`}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleClose}>Close</Button>
          {/* <Button className='btn btn-danger'>Ban Organization</Button> */}
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ViewReport;
