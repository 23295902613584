import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import styles from './Platform.module.sass';
import ServiceFee from './ServiceFee';
// import ShippingPrice from "./ShippingPrice";
import ImageModeration from './ImageModeration';
import TextModeration from './TextModeration';
import axios from 'axios';
import { platformSettingsListing } from '../../environment';

const Platform = () => {
  const [platformSettings, setPlatformSettings] = useState([]);

  const fetchPlatFormSettings = async () => {
    const settings = await axios.get(platformSettingsListing, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });

    console.log('settings?.data :', settings?.data?.data);
    if (settings?.data) {
      setPlatformSettings(settings?.data?.data);
    }
  };

  useEffect(() => {
    fetchPlatFormSettings();
  }, []);

  //   console.log('platformSettings :', platformSettings);
  return (
    <>
      <div className={styles.row}>
        <div className={styles.col}>
          <ServiceFee settings={platformSettings} fetchPlatFormSettings={fetchPlatFormSettings} />
        </div>
        {/* <div className={styles.col}>
                <ShippingPrice />
            </div> */}
        {/* <div className={styles.col}>
          <ImageModeration />
        </div>
        <div className={styles.col}>
          <TextModeration />
        </div> */}
      </div>
    </>
  );
};

export default Platform;
