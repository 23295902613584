import React, { useState } from 'react';
import cn from 'classnames';
import styles from './ForgetPassword.module.sass';
import { use100vh } from 'react-div-100vh';
import { ThreeDots } from 'react-loader-spinner';
import TextInput from '../../components/TextInput';
import Image from '../../components/Image';
import { postRequest } from '../../apiConnection/api';
import { forgetPasswordRequest } from '../../environment';
import {
  notifySuccess,
  notifyError,
} from '../../apiConnection/notification.api';

const ForgetPassword = () => {
  const heightWindow = use100vh();
  const [email, setEmail] = useState('');
  const [loading, setIsLoading] = useState(false);
  const handleChangeEmail = (value) => {
    setEmail(value);
  };
  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      const response = await postRequest(forgetPasswordRequest, {
        email,
      });
      if (response && response.data) {
        console.log('Here we are');
        setTimeout(() => {
          setIsLoading(false);
          notifySuccess(response?.message);
        }, 1000);
        // history.push('/');
      }
    } catch (error) {
      notifyError(error.message);
    }
  };
  return (
    <div className={styles.login} style={{ minHeight: heightWindow }}>
      <div className={styles.wrapper}>
        {/* <Link className={styles.logo} to='/'>
          <Image
            className={styles.pic}
            src='/images/content/logo.svg'
            srcDark='/images/content/logoDarkMode.svg'
            alt='Core'
          />
        </Link> */}
        <div className={cn('h4', styles.title)}>Forgot Password?</div>
        <div className={styles.body}>
          <div className={styles.subtitle}>
            Write your email address and we will send you password reset
            instructions.
          </div>
          <TextInput
            className={styles.field}
            name='email'
            type='email'
            placeholder='Your email'
            required
            icon='mail'
            handleChange={handleChangeEmail}
          />
          <button
            onClick={() => handleSubmit()}
            className={cn('button', styles.button)}
            disabled={loading}
          >
            {loading ? (
              <ThreeDots
                height='30'
                width='30'
                radius='9'
                color='#4fa94d'
                ariaLabel='three-dots-loading'
                wrapperStyle={{}}
                wrapperClassName=''
                visible={true}
              />
            ) : (
              'Send Password Reset Instructions'
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ForgetPassword;
