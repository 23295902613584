const initialState = {
  // Define your initial state here
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    // Handle different action types here
    default:
      return state;
  }
};

export default authReducer;
