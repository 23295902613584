import ConnectWallet from "./screens/SignIn/ConnectWallet";
import Home from "./screens/Home";
import ProductsDashboard from "./screens/ProductsDashboard";
import NewProduct from "./screens/NewProduct";
import Drafts from "./screens/Drafts";
import Released from "./screens/Released";
import Comments from "./screens/Comments";
import Scheduled from "./screens/Scheduled";
import Customers from "./screens/Customers";
import CustomerList from "./screens/CustomerList";
import Promote from "./screens/Promote";
import Notification from "./screens/Notification";
import Settings from "./screens/Settings";
import UpgradeToPro from "./screens/UpgradeToPro";
import MessageCenter from "./screens/MessageCenter";
import ExploreCreators from "./screens/ExploreCreators";
import AffiliateCenter from "./screens/AffiliateCenter";
import SignUp from "./screens/SignUp";
import SignIn from "./screens/SignIn";
import Earning from "./screens/Earning";
import Refunds from "./screens/Refunds";
import Payouts from "./screens/Payouts";
import Statements from "./screens/Statements";
import Shop from "./screens/Shop";
import PageList from "./screens/PageList";
import Listings from "./screens/Listings";
import Transactions from "./screens/Transactions";
import Shippings from "./screens/Shippings";
import Users from "./screens/Users";
import Organizations from "./screens/Organizations";
import Platform from "./screens/Platform";
import ReportListing from "./screens/ReportListing";
import Rewards from "./screens/Rewards";
import ForgetPassword from "./screens/ForgetPassword";
import ResetPassword from "./screens/ResetPassword";
import Roles from "./screens/Roles";
import CreateRole from "./screens/Roles/CreateRole";
import EditRole from "./screens/Roles/EditRole";
import PermissionsListing from "./screens/Permissions";
import CreatePermission from "./screens/Permissions/CreatePermission.jsx";
import EditPermission from "./screens/Permissions/EditPermission.jsx";
import UserRoleManagement from "./screens/UserManagement";
import Reports from "./screens/Reports";
import IsoDeterminationLetters from "./screens/IsoDeterminationLetters";
import Causes from "./screens/Causes";
import CreateCause from "./screens/Causes/CreateCause";
import User from "./screens/User";
import FiscalSponsorshipLetters from "./screens/FiscalSponsorshipLetters";
import Analytics from "./components/Analytics";

export const routes = [
  {
    name: "Home",
    path: "/",
    component: Home,
    isPublic: false,
  },
  {
    name: "Roles",
    path: "/roles",
    component: Roles,
    isPublic: false,
  },
  {
    name: "CreateRole",
    path: "/create-role",
    component: CreateRole,
    isPublic: false,
  },
  {
    name: "Edit Role",
    path: "/edit-role/:id",
    component: EditRole,
    isPublic: false,
  },
  {
    name: "Permissions",
    path: "/permissions",
    component: PermissionsListing,
    isPublic: false,
  },
  {
    name: "Create Permission",
    path: "/create-permission",
    component: CreatePermission,
    isPublic: false,
  },
  {
    name: "Edit Permission",
    path: "/edit-permission/:id",
    component: EditPermission,
    isPublic: false,
  },
  {
    name: "Users",
    path: "/users",
    component: User,
    isPublic: false,
  },
  {
    name: "Organizations",
    path: "/organizations",
    component: Organizations,
    isPublic: false,
  },
  // {
  //   name: 'Create Permission',
  //   path: '/create-permission',
  //   component: CreatePermission,
  //   isPublic: false,
  // },
  // {
  //   name: 'Edit Permission',
  //   path: '/edit-permission/:id',
  //   component: EditPermission,
  //   isPublic: false,
  // },
  {
    name: "User & Role Management",
    path: "/user-role-management",
    component: UserRoleManagement,
    isPublic: false,
  },
  {
    name: "Reports",
    path: "/reports",
    component: Reports,
    isPublic: false,
  },
  {
    name: "ISODeterminationLetters",
    path: "/irs-determination-letters",
    component: IsoDeterminationLetters,
    isPublic: false,
  },
  {
    name: "FiscalSponsorshipLetters",
    path: "/fiscal-sponsorship-letters",
    component: FiscalSponsorshipLetters,
    isPublic: false,
  },
  {
    name: "Causes",
    path: "/causes",
    component: Causes,
    isPublic: false,
  },
  {
    name: "CreateCause",
    path: "/create-cause",
    component: CreateCause,
    isPublic: false,
  },
  {
    name: "Products dashboard",
    path: "/products/dashboard",
    component: ProductsDashboard,
    isPublic: false,
  },
  {
    name: "New product",
    path: "/products/add",
    component: NewProduct,
    isPublic: false,
  },
  {
    name: "Drafts",
    path: "/products/drafts",
    component: Drafts,
    isPublic: false,
  },
  {
    name: "Released",
    path: "/products/released",
    component: Released,
    isPublic: false,
  },
  {
    name: "Comments",
    path: "/products/comments",
    component: Comments,
    isPublic: false,
  },
  {
    name: "Scheduled",
    path: "/products/scheduled",
    component: Scheduled,
    isPublic: false,
  },
  {
    name: "Customers",
    path: "/customers/overview",
    component: Customers,
    isPublic: false,
  },
  {
    name: "Customer list",
    path: "/customers/customer-list",
    component: CustomerList,
    isPublic: false,
  },
  {
    name: "Promote",
    path: "/promote",
    component: Promote,
    isPublic: false,
  },
  {
    name: "Notification",
    path: "/notification",
    component: Notification,
    isPublic: false,
  },
  {
    name: "Settings",
    path: "/settings",
    component: Settings,
    isPublic: false,
  },
  {
    name: "Upgrade to Pro",
    path: "/upgrade-to-pro",
    component: UpgradeToPro,
    isPublic: false,
  },
  {
    name: "Message center",
    path: "/message-center",
    component: MessageCenter,
    isPublic: false,
  },
  {
    name: "Explore creators",
    path: "/explore-creators",
    component: ExploreCreators,
    isPublic: false,
  },
  {
    name: "Affiliate center",
    path: "/affiliate-center",
    component: AffiliateCenter,
    isPublic: false,
  },
  {
    name: "SignUp",
    path: "/sign-up",
    component: SignUp,
    isPublic: true,
  },
  {
    name: "SignIn",
    path: "/connect-wallet",
    component: ConnectWallet,
    isPublic: true,
  },
  {
    name: "SignIn",
    path: "/sign-in",
    component: SignIn,
    isPublic: true,
  },
  {
    name: "Earning",
    path: "/income/earning",
    component: Earning,
    isPublic: false,
  },
  {
    name: "Refunds",
    path: "/income/refunds",
    component: Refunds,
    isPublic: false,
  },
  {
    name: "Payouts",
    path: "/income/payouts",
    component: Payouts,
    isPublic: false,
  },
  {
    name: "Statements",
    path: "/income/statements",
    component: Statements,
    isPublic: false,
  },
  {
    name: "Shop",
    path: "/shop",
    component: Shop,
    isPublic: false,
  },
  {
    name: "Page list",
    path: "/pagelist",
    component: PageList,
    isPublic: true,
  },
  {
    name: "Listings",
    path: "/listings",
    component: Listings,
    isPublic: false,
  },
  {
    name: "Transactions",
    path: "/transactions",
    component: Transactions,
    isPublic: false,
  },
  {
    name: "Shippings",
    path: "/shippings",
    component: Shippings,
    isPublic: false,
  },
  {
    name: "Users",
    path: "/users",
    component: Users,
    isPublic: false,
  },
  {
    name: "Platform",
    path: "/platform-setting",
    component: Platform,
    isPublic: false,
  },
  {
    name: "Report listing",
    path: "/reported-listings",
    component: ReportListing,
    isPublic: false,
  },
  {
    name: "Rewards",
    path: "/rewards",
    component: Rewards,
    isPublic: false,
  },
  {
    name: "ForgetPassword",
    path: "/forget-password",
    component: ForgetPassword,
    isPublic: true,
  },
  {
    name: "ResetPassword",
    path: "/reset-password/:token",
    component: ResetPassword,
    isPublic: true,
  },
  {
    name: "Analytics",
    path: "/analytics",
    component: Analytics,
    isPublic: false,
  },
];
