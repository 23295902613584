import React from "react";
import NFtSold from "./NftSold";
import MaticValue from "./MaticValue";
import CharitableDonation from "./CharitableDonation";

function Analytics() {

  return (
    <>
      <NFtSold />
      <MaticValue />
      <CharitableDonation />
    </>
  );
}

export default Analytics;
