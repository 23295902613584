import React, { useState, useEffect } from 'react';
import axios from 'axios';
import cn from 'classnames';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import { ThreeDots } from 'react-loader-spinner';
import TooltipGlodal from '../../components/TooltipGlodal';
import styles from '../Roles/Roles.module.sass';
import TextInput from '../../components/TextInput';
import Textarea from '../../components/Textarea';
import { CauseSchema } from './CausesSchema';
import { createCause } from '../../environment';
import {
  notifySuccess,
  notifyError,
} from '../../apiConnection/notification.api';

const CreateCause = () => {
  const history = useHistory();
  const [loading, setIsLoading] = useState(false);
  const [checkboxItems, setCheckboxItems] = useState([]);
  const [imageFile, setImageFile] = useState({});
  const [causePictureFilePreview, setCausePictureFilePreview] = useState(null);
  

  const {
    dirty,
    values,
    setFieldValue,
    handleChange,
    handleSubmit,
    handleBlur,
    touched,
    errors,
    resetForm,
    isValid,
  } = useFormik({
    onSubmit: async (values, { setErrors }) => {
      setIsLoading(true);

      try {
        const formData = new FormData();
        formData.append('categoryName', values?.categoryName);
        formData.append('description', values?.description);
        // Append the file if it exists
        if (values?.categoryImage) {
          formData.append('categoryImage', values?.categoryImage);
        }

        // const data = {
        //   categoryName: values.categoryName,
        //   description: values.description,
        //   categoryImage:
        //     Object.keys(values?.categoryImage).length === 0
        //       ? values?.categoryImage
        //       : imageFile,
        // };

        const saveData = await axios.post(createCause, formData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });

        if (saveData) {
          setErrors({});
          setIsLoading(false);
          notifySuccess(saveData?.data?.message);
          setCausePictureFilePreview('')
          resetForm();
          history.push('/causes');
        }
      } catch (error) {
        console.log('error :', error);
        notifyError(error?.response?.data?.message);
        setIsLoading(false);
      }
    },
    initialValues: {
      categoryName: '',
      description: '',
    },
    validationSchema: CauseSchema,
    validateOnBlur: true,
    validateOnMount: false,
    enableReinitialize: true,
  });

  const uploadImage = (e) => {
    setFieldValue('categoryImage', e.target.files[0]);
    setImageFile(e.target.files[0]);
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const uploadedImageUrl = event.target.result;
        setCausePictureFilePreview(uploadedImageUrl);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <>
      <div className={styles.settings}>
        <div className={styles.fieldset}>
          <TextInput
            className={styles.field}
            label='Cause name'
            name='categoryName'
            type='text'
            tooltip='Required.'
            placeholder='Enter cause name'
            required
            value={values.categoryName}
            onChange={handleChange}
            onBlur={handleBlur}
            isError={touched.categoryName && !!errors.categoryName}
            errorMessage={errors.categoryName}
          />
        </div>

        <div className={styles.imgfieldset}>
          <TextInput
            className={styles.imgfield}
            label='Image'
            name='categoryImage'
            type='file'
            onChange={uploadImage}
          />
           <p className={styles.upload} style={{cursor:'pointer'}}>Upload your file here</p>
           {/* show preview img here */}
           {causePictureFilePreview && <img src={causePictureFilePreview} alt="Cause Icon" className={styles.preview}/>}

        </div>

        <div className={styles.fieldset}>
          <TextInput
            className={styles.field}
            label='Description'
            name='description'
            type='text'
            tooltip='Required.'
            placeholder='Enter category description'
            required
            value={values.description}
            onChange={handleChange}
            onBlur={handleBlur}
            isError={touched.description && !!errors.description}
            errorMessage={errors.description}
          />
        </div>

        <div className={styles.buttonsParent}>
          <button
            className={cn('button', styles.button)}
            style={{ marginTop: 20 }}
            onClick={() => history.push('/causes')}
          >
            Back
          </button>
          <button
            onClick={handleSubmit}
            disabled={!dirty || !isValid || loading}
            className={cn('button', styles.button)}
            style={{ marginTop: 20 }}
          >
            {loading ? (
              <ThreeDots
                height='30'
                width='30'
                radius='9'
                color='#2A85FF'
                ariaLabel='three-dots-loading'
                wrapperStyle={{}}
                wrapperClassName=''
                visible={true}
              />
            ) : (
              'Save'
            )}
          </button>
        </div>
      </div>
      <TooltipGlodal />
    </>
  );
};

export default CreateCause;
