import React from 'react';

const Image = ({ className, src, srcDark, srcSet, srcSetDark, alt }) => {

  return (
    <img
      style={{ color: '#000' }}
      className={className}
      // srcSet={value ? srcSetDark : srcSet}
      // src={value ? srcDark : src}
      alt={alt}
    />
  );
};

export default Image;
