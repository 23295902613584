import React from 'react';
import styles from './reports.module.sass';
import ReportsModule from './Reports';

const Reports = () => {
  return (
    <div className={styles.outer}>
      <ReportsModule />
    </div>
  );
};

export default Reports;
