import * as Yup from 'yup';

export const CauseSchema = Yup.object().shape({
  categoryName: Yup.string()
    .trim()
    .max(255)
    .required('Cause name is required.'),
  description: Yup.string().required('Cause description is required.'),
  categoryImage: Yup.string().required('Cause image is required.'),
});
