import React from "react";
import cn from "classnames";
import styles from "./Item.module.sass";
import Tooltip from "../../Tooltip";

const Item = ({ className, onActive, item }) => {
  return (
    <div className={cn(styles.item, className)} onClick={onActive}>
      <div className={styles.icon}>
        {item.icon ? <img src={item.icon}></img>
          : <></>}
      </div>
      <div className={styles.details}>
        <div className={styles.subtitle}>
          {item.title}
          <Tooltip
            className={styles.tooltip}
            title={item.tooltip}
            icon="info"
            place="top"
          />
        </div>
        <div className={styles.counter}>{item.counter}</div>
      </div>
    </div>
  );
};

export default Item;
