import React, { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
import DataTable from "react-data-table-component";
import styles from "./reportsModule.module.sass";
import TooltipGlodal from "../../components/TooltipGlodal";
import {
  FaEye,
  FaArrowRotateRight,
  FaBan,
  FaRegShareFromSquare,
} from "react-icons/fa6";

import ViewReport from "./ViewReport";
import RecoverOrganization from "./RecoverOrganization";
import { customStyles } from "../../helpers/commonStyles";
import {
  reportsListing,
  banOrganization,
  recoverOrganization,
} from "../../environment";
import Form from "react-bootstrap/Form";
import SearchMain from "../../components/MainSearch";
import {
  notifySuccess,
  notifyError,
} from "../../apiConnection/notification.api";
import ChangeEIN from "./ChangeEIN";
const NftReport = () => {
  const [users, setUsers] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const [organization, setOrganization] = useState("");
  const [reportDetail, setReportDetail] = useState("");
  const [actionType, setActionType] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [modalMessage, setModalMessage] = useState("");
  const [show, setShow] = useState(false);
  const [changeEin, setChangeEin] = useState(false);
  const [isRecoverShow, setIsRecoverShow] = useState(false);
  const [filter, setFilter] = useState("all");
  const [search, setSearch] = useState(null);
  const getReportsListing = async () => {
    const reports = await axios.get(reportsListing, {
      params: {
        // role: "organization",
        type:'nft',
        filter,
        ...(search && { search }),
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });

    if (reports?.data) {
      setUsers(reports?.data?.data);
    }
  };
  const handleRecoverOrganization = async (id, actionType) => {
    try {
      let response;
      if (actionType === "ban") {
        response = await axios.post(
          `${banOrganization}`,
          { id },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
      } else {
        response = await axios.post(
          `${recoverOrganization}`,
          { id },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
      }

      if (response?.data) {
        notifySuccess(response?.data?.message);
        // Update the users state instead of refetching the list
      setUsers((prevUsers) => {
        return prevUsers.map((user) => {
          // Find the specific report and update its isBan status
          if (user?.reports[0]?._id === id) {
            const updatedReports = user.reports.map((report) => {
              if (report._id === id) {
                return { ...report, isBan: actionType === "ban" };
              }
              return report;
            });

            return { ...user, reports: updatedReports };
          }
          return user;
        });
      });
      }
    } catch (error) {
      notifyError(error?.response?.data?.message);
    }
  };
  useEffect(() => {
    let timerId;
    if (search?.length > 3 || !search) {
      // Clear any existing timer
      clearTimeout(timerId);

      // Set a new timer to trigger getReportsListing after 1 second
      timerId = setTimeout(() => {
        getReportsListing();
      }, 500);
    }

    // Cleanup function to clear the timer if component unmounts or search changes
    return () => {
      clearTimeout(timerId);
    };
  }, [search]);
  useEffect(() => {
    getReportsListing();
  }, [filter]);

  const columns = [
    {
      name: "Reporter Name",
      selector: (row) =>
        row?.reportedByUser?.firstName + " " + row?.reportedByUser?.lastName,
    },
    {
      name: "Reported Profile",
      selector: (row) =>
        row?.reportedToUser?.firstName + " " + row?.reportedToUser?.lastName,
    },
    
    {
      name: "Reported NFT Url",
      selector: (row) => (
        <a
          href={`${process.env.REACT_APP_FRONTEND_BASE_URL}/nft/${row?.reports[0]?.nftId}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {`${process.env.REACT_APP_FRONTEND_BASE_URL}/nft/${row?.reports[0]?.nftId}`}
        </a>
      ),
    },
    {
      name: "Number of Reports",
      selector: (row) => row?.reports?.length,
      sortable: true,
    },
    {
      name: "NFT Reporters",
      selector: (row) =>
        row?.reports
          ?.map((report) => report?.reportedByUser?.firstName)
          .join(", "),
    },
    // {
    //   name: 'Reported Date',
    //   selector: (row) => moment(row.createdAt).format('MMMM, DD, YYYY'),
    // },
    {
      name: "Actions",
      cell: (row) => (
        <>
          <button
            className={styles.viewButton}
            onClick={() => {
              setDeleteId(row?._id);
              setReportDetail(row);
              setShow(true);
            }}
          >
            <FaEye /> View
          </button>

          {row?.reports[0]?.isBan === true ? (
            <button
              className={styles.recoverButton}
              onClick={() => {
                handleRecoverOrganization(row?.reports[0]?._id, "recover");
              }}
            >
              <FaArrowRotateRight /> Reinstate
            </button>
          ) : (
            <button
              className={styles.banButton}
              onClick={() => {
                handleRecoverOrganization(row?.reports[0]?._id, "ban");
              }}
            >
              <FaBan /> Ban
            </button>
          )}
          {/* <button
            className={`${styles.addButton} ms-1`}
            onClick={() => {
              setOrganization(row?.reportedToUser)
              setChangeEin(true);
            }}
          >
            <FaRegShareFromSquare /> Change EIN
          </button> */}
        </>
      ),
    },
  ];

  const data = users;

  return (
    <>
      <div className={styles.settings}>
        <div
          className={`${styles.btnOuter} d-flex align-items-center justify-content-between`}
        >
          <h3>NFT Reports</h3>
          {/* <div className="formOuter">
            <Form.Select onChange={(e) => setFilter(e.target.value)}>
              <option value="all">Show all organization</option>
              <option value="banned">Ban organization</option>
              <option value="unbanned">Unban organization</option>
            </Form.Select>
          </div> */}
        </div>
        <SearchMain onChange={(e) => setSearch(e.target.value)} />
        <div className={styles.orgTable}>
          <DataTable
            columns={columns}
            data={data}
            pagination
            customStyles={customStyles}
          />
        </div>
      </div>
      <ViewReport
        show={show}
        id={deleteId}
        setShow={setShow}
        getReportsListing={getReportsListing}
        reportDetail={reportDetail}
      />

      <RecoverOrganization
        show={isRecoverShow}
        id={deleteId}
        setShow={setIsRecoverShow}
        actionType={actionType}
        modalTitle={modalTitle}
        modalMessage={modalMessage}
        getReportsListing={getReportsListing}
      />
      <ChangeEIN
        organization={organization}
        changeEin={changeEin}
        setChangeEin={setChangeEin}
        getReportsListing={getReportsListing}
      />
      <TooltipGlodal />
    </>
  );
};

export default NftReport;
