import { CONTRACT_DETAILS } from "..";
import { getContractMethods, connectMetamask } from "../../web3";
export const transferOwnership = async (address) => {
  try {
    const methods = await getContractMethods(
      CONTRACT_DETAILS.marketPlace.abi,
      CONTRACT_DETAILS.marketPlace.address
    );
    const connectedAddress = await connectMetamask();
    const receipt = await methods
      .transferOwnership(address)
      .send({ from: connectedAddress })
      .on("transactionHash", (hash) => {
        console.log(hash,"hash==>")
        // onTransactionHash(hash, stakeData);
      });
    return receipt;
  } catch (err) {
    console.log(err, "error=>");
    throw Error(err);
  }
};
export const updatePlatformFee = async (_serviceFeePercentage) => {
  try {
    const methods = await getContractMethods(
      CONTRACT_DETAILS.marketPlace.abi,
      CONTRACT_DETAILS.marketPlace.address
    );
    const connectedAddress = await connectMetamask();
    const receipt = await methods
      .setPlatFormServiceFeePercentage(_serviceFeePercentage)
      .send({ from: connectedAddress })
      .on("transactionHash", (hash) => {
        console.log(hash,"hash==>")
        // onTransactionHash(hash, stakeData);
      });
    return receipt;
  } catch (err) {
    console.log(err, "error=>");
    throw Error(err);
  }
};
