import React, { useEffect, useMemo, useState } from "react";
import styles from "./Listings.module.sass";
import Overview from "../../components/Overview";
import { useGetNFTs } from "../../hooks/nftHooks";

let overviewNav = [
    [
        {
            title: "Active Listings",
            counter: "0",
            icon: "/images/content/tag.svg",
            color: "#B1E5FC",
            value: -37.8,
        },
        {
            title: "Sold Items",
            counter: "0",
            icon: "/images/content/bag.svg",
            color: "#CABDFF",
            value: 37.8,
        }
    ],
    [
        {
            title: "Active Auctions",
            counter: "0",
            icon: "/images/content/speaker.svg",
            color: "#B1E5FC",
            value: -37.8,
        },
        {
            title: "Removed Listings",
            counter: "0",
            icon: "/images/content/nft.svg",
            color: "#CABDFF",
            value: 37.8,
        },
    ]
];

let categoriesNav = [
    [
        {
            title: "Electronics",
            counter: "0",
            icon: "/images/content/electronics.svg",
            color: "#B1E5FC",
            value: -37.8,
        },
        {
            title: "Entertainment",
            counter: "0",
            icon: "/images/content/entertainment.svg",
            color: "#CABDFF",
            value: 37.8,
        }
    ],
    [
        {
            title: "Home & Garden",
            counter: "0",
            icon: "/images/content/sofa.svg",
            color: "#B1E5FC",
            value: -37.8,
        },
        {
            title: "Fashion",
            counter: "0",
            icon: "/images/content/fashion.svg",
            color: "#CABDFF",
            value: 37.8,
        },
    ],
    [
        {
            title: "Sports & Hobbies",
            counter: "0",
            icon: "/images/content/sports.svg",
            color: "#B1E5FC",
            value: -37.8,
        },
        {
            title: "Toys & Games",
            counter: "0",
            icon: "/images/content/games.svg",
            color: "#CABDFF",
            value: 37.8,
        },
    ],
    [
        {
            title: "Others",
            counter: "0",
            icon: "/images/content/cart.svg",
            color: "#B1E5FC",
            value: -37.8,
        },
        {
            title: "Vehicles",
            counter: "0",
            icon: "/images/content/car.svg",
            color: "#CABDFF",
            value: 37.8,
        },
    ]
];

const Listings = () => {
    const [nftList, setNftList] = useState([])
    const [days, setDays] = useState('');
    const { refetch } = useGetNFTs(days, setNftList)

    useEffect(() => {
        refetch()
    }, [days])

    useMemo(() => {
        overviewNav = [
            [
                {
                    title: "Active Listings",
                    counter: nftList.activeListings,
                    icon: "/images/content/tag.svg",
                    color: "#B1E5FC",
                    value: -37.8,
                },
                {
                    title: "Sold Items",
                    counter: nftList.soldItems,
                    icon: "/images/content/bag.svg",
                    color: "#CABDFF",
                    value: 37.8,
                }
            ],
            [
                {
                    title: "Active Auctions",
                    counter: nftList.activeAuctions,
                    icon: "/images/content/speaker.svg",
                    color: "#B1E5FC",
                    value: -37.8,
                },
                {
                    title: "Removed Listings",
                    counter: nftList.removedListings,
                    icon: "/images/content/nft.svg",
                    color: "#CABDFF",
                    value: 37.8,
                },
            ]
        ];

        categoriesNav = [
            [
                {
                    title: "Electronics",
                    counter: nftList?.productsObj?.Electronics,
                    icon: "/images/content/electronics.svg",
                    color: "#B1E5FC",
                    value: -37.8,
                },
                {
                    title: "Entertainment",
                    counter: nftList?.productsObj?.Entertainment,
                    icon: "/images/content/entertainment.svg",
                    color: "#CABDFF",
                    value: 37.8,
                }
            ],
            [
                {
                    title: "Home & Garden",
                    counter: nftList.productsObj && nftList.productsObj.hasOwnProperty("Home & Garden")
                        ? nftList.productsObj["Home & Garden"] : null,
                    icon: "/images/content/sofa.svg",
                    color: "#B1E5FC",
                    value: -37.8,
                },
                {
                    title: "Fashion",
                    counter: nftList?.productsObj?.Fashion,
                    icon: "/images/content/fashion.svg",
                    color: "#CABDFF",
                    value: 37.8,
                },
            ],
            [
                {
                    title: "Sports & Hobbies",
                    counter: nftList.productsObj && nftList.productsObj.hasOwnProperty("Sports & Hobbies")
                        ? nftList.productsObj["Sports & Hobbies"] : null,
                    icon: "/images/content/sports.svg",
                    color: "#B1E5FC",
                    value: -37.8,
                },
                {
                    title: "Toys & Games",
                    counter: nftList.productsObj && nftList.productsObj.hasOwnProperty("Toys & Games")
                        ? nftList.productsObj["Toys & Games"] : null,
                    icon: "/images/content/games.svg",
                    color: "#CABDFF",
                    value: 37.8,
                },
            ],
            [
                {
                    title: "Others",
                    counter: nftList?.productsObj?.Others,
                    icon: "/images/content/cart.svg",
                    color: "#B1E5FC",
                    value: -37.8,
                },
                {
                    title: "Vehicles",
                    counter: nftList?.productsObj?.Vehicles,
                    icon: "/images/content/car.svg",
                    color: "#CABDFF",
                    value: 37.8,
                },
            ]
        ]
    }, [nftList])

    return (
        <>
            <div className={styles.row}>
                <div className={styles.col}>
                    <Overview className={styles.card} nav={overviewNav} title="Overview" classTitle="title-red" setDays={setDays} />
                </div>
            </div>
            <div className={styles.row}>
                <div className={styles.col}>
                    <Overview className={styles.card} nav={categoriesNav} title="Categories" classTitle="title-purple" />
                </div>
            </div>
        </>
    );
};

export default Listings;