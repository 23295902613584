import React, { useState } from 'react';
import Classes from './index.module.scss';
import SearchIcon from '../../assets/images/search.svg';
// import { Select } from 'antd';

const SearchMain = ({ onChange }) => {
  const [selectedOption, setSelectedOption] = useState('collection');

  const handleChange = (value) => {
    setSelectedOption(value);
  };

  return (
    <div
      className={`${Classes.searchMain} d-flex gap-2 align-items-center my-4`}
    >
      {/* <Select
        placeholder='Find a cause'
        style={{
          width: 160,
        }}
        onChange={handleChange}
        options={[
          {
            value: 1,
            label: 'Find a cause',
          },
          {
            value: 2,
            label: 'New additions',
          },
          {
            value: 3,
            label: 'Popular nfts',
          },
          {
            value: 4,
            label: 'Urgent appeals',
          },
        ]}
      /> */}
      <div className={Classes.search}>
         <img className='pe-2' src={SearchIcon} alt='icon' /> 
        <input placeholder='Search....' onChange={onChange} />
        {/* <button className={Classes.blueBtn} type='button'>
          Search
        </button> */}
      </div>
      <div></div>
    </div>
  );
};

export default SearchMain;
