import axios from 'axios';
import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import {
  notifySuccess,
  notifyError,
} from '../../apiConnection/notification.api';
import { banOrganization, recoverOrganization } from '../../environment';

const RecoverOrganization = ({
  id,
  show,
  setShow,
  getReportsListing,
  modalTitle,
  modalMessage,
  actionType,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const handleClose = () => {
    setShow(false);
  };

  const handleRecoverOrganization = async () => {
    try {
      setIsLoading(true);
      let response;
      if (actionType === 'ban') {
        response = await axios.post(
          `${banOrganization}`,
          {id },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          }
        );
      } else {
        response = await axios.post(
          `${recoverOrganization}`,
          {id },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          }
        );
      }

      if (response?.data) {
        setIsLoading(false);
        handleClose();
        notifySuccess(response?.data?.message);
        getReportsListing();
      }
    } catch (error) {
      setIsLoading(false);
      console.log('error :', error);
      handleClose();
      notifyError(error?.response?.data?.message);
    }
  };

  return (
    <div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <h3>{modalTitle}</h3>
        </Modal.Header>
        <Modal.Body>
          <p>{modalMessage}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleClose}>Close</Button>
          <Button
            onClick={() => {
              handleRecoverOrganization();
            }}
            className={
              actionType === 'ban' ? 'btn btn-danger' : 'btn btn-success'
            }
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default RecoverOrganization;
