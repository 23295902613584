import React, { useEffect } from "react";
import cn from "classnames";
import styles from "./ServiceFee.module.sass";
import Card from "../../components/Card";
import Image from "../../components/Image";
import TextInput from "../../components/TextInput";
import { useState } from "react";
import {
  notifyError,
  notifySuccess,
} from "../../apiConnection/notification.api";
import {
  rewardControls,
  rewardControlsDetails,
  rewardsList,
} from "../../environment";
import CustumToggleButton from "../../components/CustumToggleButton";
import axios from "axios";
import CsvDownloadButton from "react-json-to-csv";

const Rewards = () => {
  const [rewardAmount, setRewardAmount] = useState(0);
  const [rewardControl, setRewardControl] = useState(false);
  const [rewardList, setRewardList] = useState([]);
  const handleChange = (value) => {
    setRewardAmount(value);
  };

  const switchHandler = async (e) => {
    await axios
      .post(rewardControls, { rewardAmount, rewardControl: e.target.checked })
      .then((res) => {
        notifySuccess(res.data.message);
        setRewardControl(res.data.rewardControl.rewardControl);
      })
      .catch((error) => {
        console.log(error);
        notifyError(error);
      });
  };
  const getRewardsControlsDetails = async () => {
    await axios
      .get(rewardControlsDetails)
      .then((res) => {
        setRewardAmount(res.data.rewardControl.rewardAmount);
        setRewardControl(res.data.rewardControl.rewardControl);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSubmit = async () => {
    await axios
      .post(rewardControls, { rewardAmount, rewardControl })
      .then((res) => {
        notifySuccess(res.data.message);
        setRewardAmount(res.data.rewardControl.rewardAmount);
      })
      .catch((error) => {
        console.log(error);
        notifyError(error);
      });
  };

  const downloadCSVHandler = async () => {
    await axios
      .get(rewardsList)
      .then((res) => {
        setRewardList(res.data.rewardsList);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getRewardsControlsDetails();
    downloadCSVHandler();
  }, []);

  return (
    <>
      <div className={styles.row}>
        <div className={styles.col}>
          <Card title="First listing reward" classTitle="title-red">
            <div className={styles.infoRight}>
              <div className={styles.switchRight}>
                <CustumToggleButton
                  // id={i}
                  check={rewardControl}
                  onchange={(e) => switchHandler(e)}
                />
              </div>
            </div>
            <div className={cn("generalRow")}>
              <div className={cn("labelRow")}>
                <label className={cn("labelStyle")}>Reward amount</label>
                <Image src="/images/content/infoIcon.svg" />
              </div>
              <div className="inputRow">
                <TextInput
                  className={styles.input}
                  label=""
                  name="amount"
                  type="number"
                  icon="setting"
                  placeholder="5 MATIC"
                  value={rewardAmount}
                  tooltip=""
                  required
                  handleChange={handleChange}
                />
              </div>
              <button className={cn("transparentBtn")} onClick={handleSubmit}>
                Change reward amount
              </button>
              <CsvDownloadButton
                className={cn("transparentBtn")}
                filename='reward-List'
                delimiter=","

                data={rewardList}
              >
                Check/Download CSV
              </CsvDownloadButton>
            </div>
          </Card>
        </div>
      </div>
    </>
  );
};

export default Rewards;
