import React, { useState } from 'react';
import axios from 'axios';
import { Modal, Button, Form } from 'react-bootstrap';
import { useFormik } from 'formik';
import { twoFactorVerification } from '../../../environment';
import styles from './TwoFa.module.sass';
import { ThreeDots } from 'react-loader-spinner';
import cn from 'classnames';
import {
  notifySuccess,
  notifyError,
} from '../../../apiConnection/notification.api';
import { TwoFactorSchema } from './TwoFactorSchema';

const EnableTwoFactor = ({ show, setShow, twoFaBase,setIsTwoFaEnabled }) => {
  const handleClose = () => {
    setShow(false);
  };

  const [loading, setIsLoading] = useState(false);

  const {
    dirty,
    values,
    handleChange,
    handleSubmit,
    handleBlur,
    touched,
    errors,
    resetForm,
    isValid,
  } = useFormik({
    onSubmit: async (values, { setErrors }) => {
      setIsLoading(true);
      const user = JSON.parse(localStorage.getItem('user'));

      const headers = {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      };

      try {
        const verifyOTP = await axios.post(
          twoFactorVerification,
          {
            otp: values.otp,
            email: user ? user.email : '',
            requestType: 'optActivation',
          },
          {
            headers: headers,
          }
        );
        if (verifyOTP) {
          setErrors({});
          setIsLoading(false);
          notifySuccess(verifyOTP?.data?.message);
          resetForm();
          setIsTwoFaEnabled(true);
          setShow(false);
          localStorage.setItem('user', JSON.stringify(verifyOTP?.data?.data)); // Update local storage with new user data
        }
      } catch (error) {
        console.log('error :', error);
        notifyError(error?.response?.data?.message);
        setIsLoading(false);
      }
    },
    initialValues: {
      otp: '',
    },
    validationSchema: TwoFactorSchema,
    validateOnBlur: true,
    validateOnMount: false,
    enableReinitialize: true,
  });

  return (
    <div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header className={styles.txt} closeButton>Confirmation Alert!!!</Modal.Header>
        <Modal.Body>
          <p className={styles.txt}>
            Scan the QR code below with your authentication application, such as
            Google Authenticator, on your phone
          </p>
          <div className={styles.code}>
            {twoFaBase && <img src={twoFaBase} alt='' />}
          </div>
          <div className={styles.fieldset}>
            <Form.Label className={styles.txt}>
              Type the code generated by authenticator app
            </Form.Label>

            <input
              className={styles.fieldabc}
              type='text'
              placeholder='Activation code'
              label='Confirm new password'
              name='otp'
              value={values.otp}
              onChange={handleChange}
              onBlur={handleBlur}
              isError={touched.otp && !!errors.otp}
              errorMessage={errors.otp}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button onClick={handleClose} className={cn('button', styles.button)}>
            Close
          </button>

          <button
            onClick={handleSubmit}
            className={cn('button', styles.button)}
            disabled={!dirty || !isValid || loading}
            style={{ background: '#198754' }}
          >
            {loading ? (
              <ThreeDots
                height='30'
                width='30'
                radius='9'
                color='#2A85FF'
                ariaLabel='three-dots-loading'
                wrapperStyle={{}}
                wrapperClassName=''
                visible={true}
              />
            ) : (
              'Enable Two Factor'
            )}
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default EnableTwoFactor;
