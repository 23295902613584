import React, { useEffect, useMemo, useState } from 'react';
import styles from './Users.module.sass';
import Overview from '../../components/Overview';
import { useGetUsers } from '../../hooks/nftHooks';

let overviewNav = [
  [
    {
      title: 'Connected wallets',
      counter: 0,
      icon: '/images/content/wallet.svg',
      color: '#B1E5FC',
      value: -37.8,
    },
    {
      title: 'Removed wallets',
      counter: 0,
      icon: '/images/content/cancel.svg',
      color: '#CABDFF',
      value: 37.8,
    },
  ],
];

let categoriesNav = [
  [
    {
      title: 'Metamask wallet',
      counter: 0,
      icon: '/images/content/metamask.svg',
      color: '#B1E5FC',
      value: -37.8,
    },
    {
      title: 'Coinbase wallet',
      counter: 0,
      icon: '/images/content/coinbase.svg',
      color: '#CABDFF',
      value: 37.8,
    },
  ],
  [
    {
      title: 'Wallet Connect',
      counter: 0,
      icon: '/images/content/walletConnect.svg',
      color: '#B1E5FC',
      value: -37.8,
    },
    {
      title: 'MyEtherWallet',
      counter: 0,
      icon: '/images/content/myEtherWallet.svg',
      color: '#CABDFF',
      value: 37.8,
    },
  ],
];

const Users = () => {
  const [usersData, setUsersData] = useState([]);
  const [days, setDays] = useState('');
  const { refetch } = useGetUsers(days, setUsersData);

  useEffect(() => {
    refetch();
  }, [days]);

  useMemo(() => {
    overviewNav = [
      [
        {
          title: 'Connected wallets',
          counter: usersData.totalWallets,
          icon: '/images/content/wallet.svg',
          color: '#B1E5FC',
          value: -37.8,
        },
        {
          title: 'Removed wallets',
          counter: 0,
          icon: '/images/content/cancel.svg',
          color: '#CABDFF',
          value: 37.8,
        },
      ],
    ];

    categoriesNav = [
      [
        {
          title: 'Metamask wallets',
          counter: usersData.metamaskUsers,
          icon: '/images/content/metamask.svg',
          color: '#B1E5FC',
          value: -37.8,
        },
        {
          title: 'Coinbase wallets',
          counter: usersData.coinBaseUsers,
          icon: '/images/content/coinbase.svg',
          color: '#CABDFF',
          value: 37.8,
        },
      ],
      [
        {
          title: 'WalletConnect',
          counter: usersData.walletConnectUsers,
          icon: '/images/content/walletConnect.svg',
          color: '#B1E5FC',
          value: -37.8,
        },
        {
          title: 'MyEtherWallet',
          counter: usersData.myEtherWalletUsers,
          icon: '/images/content/myEtherWallet.svg',
          color: '#CABDFF',
          value: 37.8,
        },
      ],
    ];
  }, [usersData]);

  return (
    <>
      <div className={styles.row}>
        <div className={styles.col}>
          <Overview
            className={styles.card}
            nav={overviewNav}
            title='Overview'
            classTitle='title-red'
            setDays={setDays}
          />
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.col}>
          <Overview
            className={styles.card}
            nav={categoriesNav}
            title='Wallet Type'
            classTitle='title-purple'
          />
        </div>
      </div>
    </>
  );
};

export default Users;
