import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import styles from './ProfileInformation.module.sass';
import Item from '../Item';
import Icon from '../../../components/Icon';
import TextInput from '../../../components/TextInput';
import Editor from '../../../components/Editor';
import * as Yup from "yup";
import { Formik } from "formik";
import { getRequest } from '../../../apiConnection/api';
import { getAccountInfo,updateUserProfile } from '../../../environment';
import { EditProfileSchema } from './EditProfileSchema';
import { useEditProfile } from '../../../hooks/editProfileHooks';
import axios from "axios";
import { BiSolidMessageSquareEdit } from "react-icons/bi";
import {
  notifySuccess,
  notifyError,
} from "../../../apiConnection/notification.api"

const ProfileInformation = ({ className }) => {
  const { mutate: editProfileMutation } = useEditProfile();
  const [user, setUser] = useState({});
  const [profilePictureFile, setProfilePictureFile] = useState(null);
  const [profilePictureFilePreview, setProfilePictureFilePreview] = useState(null);

  useEffect(() => {
    const userInfo = JSON.parse(localStorage.getItem('user'));
    if (userInfo) {
      setUser(userInfo);
      setProfilePictureFilePreview(userInfo.profilePicture); // Set initial profile picture preview
    }
  }, []);

  const handleProfilePictureUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setProfilePictureFile(file);
      const reader = new FileReader();
      reader.onload = (event) => {
        const uploadedImageUrl = event.target.result;
        setProfilePictureFilePreview(uploadedImageUrl);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleProfileUpdate = async (data) => {
    try {
      const authToken = localStorage.getItem("token");
      let requestData = {
        ...data,
        authToken,
      };

      const formData = new FormData();
      formData.append("firstName", requestData?.firstName);
      formData.append("lastName", requestData?.lastName);
      formData.append("email", requestData?.email);

      if (profilePictureFile) {
        formData.append("profileImage", profilePictureFile);
      }

      const response = await axios.post(updateUserProfile, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${authToken}`,
        },
      });

      if (response.status === 200) {
        notifySuccess(response?.data?.message);
        localStorage.setItem('user', JSON.stringify(response.data.data)); // Update local storage with new user data
      }
    } catch (error) {
      console.log(error);
      notifyError(error?.message);
    }
  };

  return (
    <Item
      className={cn(styles.card, className)}
      title="Profile information"
      classTitle="title-green"
    >
      <div className={styles.profile}>
        <div className={styles.avatar}>
        <p>Upload Profile image</p>
          <div className={styles.file}>
            <input
              type="file"
              accept="image/*"
              onChange={handleProfilePictureUpload} 
            />
            <BiSolidMessageSquareEdit className={styles.editIcon} />
            <img src={profilePictureFilePreview} alt="Profile" />
          </div>
        </div>
      </div>

      <Formik
        initialValues={{
          email: user.email || '',
          firstName: user.firstName || '',
          lastName: user.lastName || '',
        }}
        validationSchema={Yup.object().shape({
          firstName: Yup.string()
            .max(20, 'First name should not exceed 20 characters.')
            .required('First name is required.'),
          lastName: Yup.string()
            .max(20, 'Last name should not exceed 20 characters.')
            .required('Last name is required.'),
          email: Yup.string()
            .email('Invalid email format')
            .required('Email is required.'),
        })}
        onSubmit={(values, { resetForm }) => {
          const data = {
            firstName: values?.firstName,
            lastName: values?.lastName,
            email: values?.email,
          };
          handleProfileUpdate(data, resetForm);
        }}
        enableReinitialize // This ensures the form is re-initialized when user data changes
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values,
        }) => (
          <form
            onSubmit={handleSubmit}
            className="mt-8 space-y-6"
            noValidate
            autoComplete="off"
          >
            <div className={styles.fieldset}>
              <TextInput
                className={styles.field}
                label="First name"
                name="firstName"
                type="text"
                tooltip="Maximum 100 characters. No HTML or emoji allowed"
                value={values.firstName}
                onChange={handleChange}
                onBlur={handleBlur}
                isError={touched.firstName && !!errors.firstName}
                errorMessage={errors.firstName}
                required
              />
              <TextInput
                className={styles.field}
                label="Last name"
                name="lastName"
                type="text"
                tooltip="Maximum 100 characters. No HTML or emoji allowed"
                value={values.lastName}
                onChange={handleChange}
                onBlur={handleBlur}
                isError={touched.lastName && !!errors.lastName}
                errorMessage={errors.lastName}
                required
              />
              <TextInput
                className={styles.field}
                label="Email"
                name="email"
                type="email"
                tooltip="Maximum 100 characters. No HTML or emoji allowed"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                isError={touched.email && !!errors.email}
                errorMessage={errors.email}
                required
              />
            </div>

            <button className={cn("button", styles.button)} type="submit">
              Update
            </button>
          </form>
        )}
      </Formik>
    </Item>
  );
};

export default ProfileInformation;
