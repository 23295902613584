import axios from 'axios';
import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { destroyPermission } from '../../environment';
import {
  notifySuccess,
  notifyError,
} from '../../apiConnection/notification.api';

const DeletePermission = ({ id, show, setShow, getPermissionListing }) => {
  const handleClose = () => {
    setShow(false);
  };

  const deletePermission = async () => {
    try {
      const delPermission = await axios.delete(`${destroyPermission}/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

      if (delPermission?.data) {
        handleClose();
        notifySuccess(delPermission?.data?.message);
        getPermissionListing();
      }
    } catch (error) {
      console.log('error :', error);
      handleClose();
      notifyError(error?.response?.data?.message);
    }
  };

  return (
    <div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>Confirmation Alert!!!</Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this permission ?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleClose}>Close</Button>
          <Button
            onClick={() => {
              deletePermission();
            }}
            className='btn btn-danger'
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default DeletePermission;
