import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import cn from 'classnames';
import OutsideClickHandler from 'react-outside-click-handler';
import styles from './User.module.sass';
import Icon from '../../Icon';
import { getAccountInfo } from '../../../environment';
import { getRequest } from '../../../apiConnection/api';

const items = [
  {
    menu: [
      {
        title: 'Edit profile',
        url: '/settings',
      },
    ],
  },
  {
    menu: [
      {
        title: 'Log out',
        url: '/sign-in',
      },
    ],
  },
];

const User = ({ className }) => {
  const [visible, setVisible] = useState(false);
  const [user, setUser] = useState({});

  useEffect(async () => {
    const userInfo = localStorage.getItem('user');
    console.log('userInfo :', JSON.parse(userInfo)?._id);
    const userInformation = await getRequest(
      `${getAccountInfo}/${JSON.parse(userInfo)?._id}`
    );
    if (userInformation.data) {
      setUser(userInformation.data);
    }
  }, []);

  return (
    <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
      <div className={cn(styles.user, className, { [styles.active]: visible })}>
        <button className={styles.head} onClick={() => setVisible(!visible)}>
          <img src={user.profilePicture && user.profilePicture} alt='Avatar' />
        </button>
        <div className={styles.body}>
          {items.map((item, index) => (
            <div className={styles.menu} key={index}>
              {item.menu.map((x, index) =>
                x.url ? (
                  <NavLink
                    className={cn(styles.item, { [styles.color]: x.color })}
                    activeClassName={styles.active}
                    to={x.url}
                    onClick={() => {
                      x.title == 'Log out' && localStorage.removeItem('token');
                      setVisible(false);
                    }}
                    key={index}
                  >
                    {x.icon && <Icon name={x.icon} size='24' />}
                    {x.title}
                  </NavLink>
                ) : (
                  <button
                    className={styles.item}
                    onClick={() => setVisible(false)}
                    key={index}
                  >
                    {x.title}
                  </button>
                )
              )}
            </div>
          ))}
        </div>
      </div>
    </OutsideClickHandler>
  );
};

export default User;
