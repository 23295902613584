// const { createAlchemyWeb3 } = require('@alch/alchemy-web3');
// const contractInfo = require('./contractInfo');
import Web3 from "web3";
import { toast } from "react-toastify";
export const getWeb3 = async () => {
  let instance;
  if (window?.ethereum) {
    await window.web3.currentProvider.enable();
    instance = new Web3(window.web3.currentProvider);
  } else {
    instance = new Web3("http://localhost:7545");
  }
  return instance;

};

export const getContractMethods = async (abi, address) => {
  const web3 = await getWeb3();
  const methods = new web3.eth.Contract(abi, address).methods;
  // console.log(methods, 'methods1==>>');
  return methods;
};
export const connectMetamask = async () => {
  if (window.ethereum) {
    let web3 = await getWeb3();
    let accounts = await web3.eth.getAccounts();
    if (accounts?.length === 0) {
      await connectMetamask();
    }
    let chainId = await web3.eth.getChainId();
    // if (chainId !== requiredChainId) {
    // 	// empty block statement
    // }
    return accounts[0];
  } else {
    toast.error("Please install metamask ");
  }
};
export const getChainId=async()=>{
  let web3 = await getWeb3();
  let chainId = await web3.eth.getChainId();
  return chainId
}
export const getConnectedAddress=async ()=>{
  if (window.ethereum) {
    let web3 = await getWeb3();
    let accounts = await web3.eth.getAccounts();
    return accounts[0];
  } else {
    toast.error("Please install metamask to connect to the Marketplace");
  }
}
export const connectWithMetamask = async () => {
  try {
    let web3 = await getWeb3();

    await window.ethereum.request({
      method: "wallet_requestPermissions",
      params: [
        {
          eth_accounts: {},
        },
      ],
    });
    let accounts = await web3.eth.getAccounts();
    return accounts[0];
  } catch (err) {
    toast.error(err.message);
  }
};
