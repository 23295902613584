import React, { useEffect, useState } from 'react';
// import cn from "classnames";
import styles from './ReportListing.module.sass';
import Card from '../../components/Card';
import axios from 'axios';
import { imageURL, reportsListing } from '../../environment';
const ReportListing = () => {
  const [reports, setReports] = useState([]);
  const fetchReportLists = () => {
    axios
      .get(reportsListing)
      .then((res) => setReports(res.data.reportsListing));
  };
  useEffect(() => {
    fetchReportLists();
  });
  return (
    <>
      <div className={styles.row}>
        <div className={styles.col}>
          {reports.map((report, i) => (
            <Card className={styles.card} key={i}>
              <div className={styles.reportWrap}>
                <div className={styles.reportLeft}>
                  <div className={styles.leftInner}>
                    <div className={styles.leftImg}>
                      <img src={imageURL + report.productId.img} alt='report' />
                    </div>
                    <div className={styles.leftInfo}>
                      <h3>{report.productId.itemName}</h3>
                      <p>
                        {new Date(report.productId.createdAt).toLocaleString()}{' '}
                        PM
                      </p>
                    </div>
                  </div>
                </div>
                <div className={styles.reportRight}>
                  <p>
                    {report.reportMessage}
                    {/* <span className={styles.hideText}>erisque enim ligula venenatis dolor. Maecenas nisl est, ultrices nec congue eget, auctor vitae massa. Fusce luctus vestibulum augue ut aliquet. Nunc sagittis dictum nisi, sed ullamcorper ipsum dignissim ac. In at libero sed nunc venenatis imperdiet sed ornare turpis. Donec vitae dui eget tellus gravida venenatis. Integer fringilla congue eros non fermentum. Sed dapibus pulvinar nibh tempor porta.</span> <a className={styles.readMore}>Read more...</a> */}
                  </p>
                </div>
              </div>
            </Card>
          ))}

          {/* <Card className={styles.card}>
                    <div className={styles.reportWrap}>
                        <div className={styles.reportLeft}>
                            <div className={styles.leftInner}>
                                <div className={styles.leftImg}>
                                    <img src="/images/content/reportimage.png" alt="report Image"/>
                                </div>
                                <div className={styles.leftInfo}>
                                    <h3>Listing name listing name here</h3>
                                    <p>28/09/2022, 05:23 PM</p>
                                </div>
                            </div>
                        </div>
                        <div className={styles.reportRight}>
                            <p>
                                Enrico sent the product as soon as I ordered and the content of the package was exactly what I was expecting.
                            </p>
                        </div>
                    </div>
                </Card>

                <Card className={styles.card}>
                    <div className={styles.reportWrap}>
                        <div className={styles.reportLeft}>
                            <div className={styles.leftInner}>
                                <div className={styles.leftImg}>
                                    <img src="/images/content/reportimage.png" alt="report Image"/>
                                </div>
                                <div className={styles.leftInfo}>
                                    <h3>Listing name listing name here</h3>
                                    <p>28/09/2022, 05:23 PM</p>
                                </div>
                            </div>
                        </div>
                        <div className={styles.reportRight}>
                            <p>
                                Enrico sent the product as soon as I ordered and the content of the package was exactly what I was expecting.
                            </p>
                        </div>
                    </div>
                </Card>

                <Card className={styles.card}>
                    <div className={styles.reportWrap}>
                        <div className={styles.reportLeft}>
                            <div className={styles.leftInner}>
                                <div className={styles.leftImg}>
                                    <img src="/images/content/reportimage.png" alt="report Image"/>
                                </div>
                                <div className={styles.leftInfo}>
                                    <h3>Listing name listing name here</h3>
                                    <p>28/09/2022, 05:23 PM</p>
                                </div>
                            </div>
                        </div>
                        <div className={styles.reportRight}>
                            <p>
                                Enrico sent the product as soon as I ordered and the content of the package was exactly what I was expecting.
                            </p>
                        </div>
                    </div>
                </Card>

                <Card className={styles.card}>
                    <div className={styles.reportWrap}>
                        <div className={styles.reportLeft}>
                            <div className={styles.leftInner}>
                                <div className={styles.leftImg}>
                                    <img src="/images/content/reportimage.png" alt="report Image"/>
                                </div>
                                <div className={styles.leftInfo}>
                                    <h3>Listing name listing name here</h3>
                                    <p>28/09/2022, 05:23 PM</p>
                                </div>
                            </div>
                        </div>
                        <div className={styles.reportRight}>
                            <p>
                                Enrico sent the product as soon as I ordered and the content of the package was exactly what I was expecting.
                            </p>
                        </div>
                    </div>
                </Card> */}
          {reports.length ? (
            <div className={styles.loadMoreWrap}>
              <button className={styles.loadMoreBtn}>Load more</button>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
};

export default ReportListing;
