import React, { useState, useEffect } from "react";
import cn from "classnames";
import styles from "./Overview.module.sass";
import Card from "../../../components/Card";
import { Col, Row } from "react-bootstrap";
import { getAccountInfo } from '../../../environment';
import { getRequest } from '../../../apiConnection/api';

const Overview = ({ className, title }) => {
  const [adminDetails, setAdminDetails] = useState();
  useEffect(async () => {
    const userInfo = localStorage.getItem('user');
    const userInformation = await getRequest(
      `${getAccountInfo}/${JSON.parse(userInfo)?._id}`
    );
    if (userInformation.data) {
      setAdminDetails(userInformation.data);
    }
  }, []);
  return (
    <Card
      className={cn(styles.card, className)}
      title={title}
      classTitle="title-red"
    >
      <div className={styles.overview}>
        <h4 className="mb-3">Hi {adminDetails?.firstName} {adminDetails?.lastName}</h4>
        <Row className="mt-2">
          <Col md={4}>
            <div className={styles.cardInner}>
              <h3>Total Users</h3>
              <p>{adminDetails?.userCount}</p>
            </div>
          </Col>
          <Col md={4}>
            <div className={styles.cardInner}>
              <h3>Total NFTs</h3>
              <p>{adminDetails?.nftCount}</p>
            </div>
          </Col>
          <Col md={4}>
            <div className={styles.cardInner}>
              <h3>Total Organizations</h3>
              <p>{adminDetails?.organizationCount}</p>
            </div>
          </Col>
        </Row>
      </div>
    </Card>
  );
};

export default Overview;
