import React, { useEffect, useState } from "react";
import axios from "axios";
import DataTable from "react-data-table-component";
import styles from "./reportsModule.module.sass";
import TooltipGlodal from "../../components/TooltipGlodal";
import { FaArrowRotateRight, FaBan } from "react-icons/fa6";

import ViewReport from "./ViewReport";
import { customStyles } from "../../helpers/commonStyles";
import { baseURL, verifyIsoLettersListing } from "../../environment";
import { CiCircleCheck } from "react-icons/ci";
import SearchMain from "../../components/MainSearch";
import {
  notifySuccess,
  notifyError,
} from "../../apiConnection/notification.api";

const IsoDeterminationLettersModule = () => {
  const [users, setUsers] = useState("");
  const [userId, setUserId] = useState("");
  const [actionId, setActionId] = useState("");
  const [reportDetail, setReportDetail] = useState("");
  const [actionType, setActionType] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [modalMessage, setModalMessage] = useState("");
  const [show, setShow] = useState(false);
  const [isRecoverShow, setIsRecoverShow] = useState(false);
  const [search, setSearch] = useState(null);

  const getIsoLettersListing = async () => {
    const letters = await axios.get(baseURL + "/iso/iso-letters/IRS", {
      params: { ...(search && { search }) },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    if (letters?.data) {
      setUsers(letters?.data?.data);
    }
  };
  const verifyISOLetter = async (actionId, actionType) => {
    try {
      const response = await axios.post(
        `${verifyIsoLettersListing}`,
        {
          documentId: actionId,
          isVerified: actionType,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response?.data) {
        notifySuccess(response?.data?.message);
        getIsoLettersListing();
      }
    } catch (error) {
      console.log("error :", error);
      // notifyError(error?.response?.data?.message);
    }
  };
  useEffect(() => {
    getIsoLettersListing();
  }, []);
  useEffect(() => {
    let timerId;
    if (search?.length > 3 || !search) {
      // Clear any existing timer
      clearTimeout(timerId);

      // Set a new timer to trigger getUsersListing after 1 second
      timerId = setTimeout(() => {
        getIsoLettersListing();
      }, 500);
    }

    // Cleanup function to clear the timer if component unmounts or search changes
    return () => {
      clearTimeout(timerId);
    };
  }, [search]);

  const columns = [
    {
      name: "Name",
      selector: (row) =>
        `${row?.userId[0]?.firstName} ${row?.userId[0]?.lastName}`,
    },
    {
      name: "Nonprofit Name",
      selector: (row) => `${row?.userId[0]?.nonProfitName}`,
    },
    {
      name: "Email",
      selector: (row) => row?.userId[0]?.email,
    },
    {
      name: "Document",
      selector: (row) => row?.isoDocument,
      cell: (row) => (
        <>
          <a href={row?.isoDocument} target="_blank">
            IRS Determination Letter
          </a>
        </>
      ),
    },
    {
      name: "EIN Number",
      selector: (row) => row?.userId[0]?.einNumber,
      cell: (row) => (
        <>
          <p>{row?.userId[0]?.einNumber}</p>
        </>
      ),
    },
    {
      name: "Status",
      selector: (row) => row?.isVerified,
      cell: (row) => (
        <>
          {row?.isVerified === "accepted" ? (
            <span className="badge rounded-pill bg-success">
              {row?.isVerified}
            </span>
          ) : (
            <span className="badge rounded-pill bg-danger">
              {row?.isVerified}
            </span>
          )}
        </>
      ),
    },
    {
      name: "Actions",
      cell: (row) => (
        <>
          <button
            className={`${styles.recoverButton} ${
              row?.isVerified === "accepted" ? styles.disabled : ""
            }`}
            style={{fontSize: '11.5px'}}
            onClick={() => {
              if (row?.isVerified !== "accepted") {
                verifyISOLetter(row?._id, "accepted");
              }
            }}
          >
            <CiCircleCheck /> Approve
          </button>
          &nbsp;&nbsp;
          <button
            className={`${styles.banButton} ${
              row?.isVerified === "rejected" ? styles.disabled : ""
            }`}
            style={{fontSize: '11.5px'}}
            onClick={() => {
              if (row?.isVerified !== "rejected") {
                verifyISOLetter(row?._id, "rejected");
              }
            }}
          >
            <FaBan /> Reject
          </button>
          {/* {row?.isVerified === 'accepted' ? (
            <button
              className={styles.recoverButton}
              onClick={() => {
                setActionId(row?._id);
                setUserId(row?.userId?._id);
                setIsRecoverShow(true);
                setActionType('accepted');
                setModalTitle('ISO determination letter approval alert');
                setModalMessage(
                  'Are you sure you want to approve this Iso Determination Letter ?'
                );
              }}
            >
              <CiCircleCheck />
            </button>
          ) : row?.isVerified === 'rejected' ? (
            <button
              className={styles.banButton}
              onClick={() => {
                setActionId(row?._id);
                setUserId(row?.userId?._id);
                setIsRecoverShow(true);
                setActionType('rejected');
                setModalTitle('ISO determination letter rejection alert');
                setModalMessage(
                  'Are you sure you want to reject this Iso Determination Letter ?'
                );
              }}
            >
              <FaBan />
            </button>
          ) : (
            <>
              <button
                className={styles.recoverButton}
                onClick={() => {
                  setActionId(row?._id);
                  setUserId(row?.userId?._id);
                  setIsRecoverShow(true);
                  setActionType('accepted');
                  setModalTitle('ISO determination letter approval alert');
                  setModalMessage(
                    'Are you sure you want to approve this Iso Determination Letter ?'
                  );
                }}
              >
                <CiCircleCheck />
              </button>
              &nbsp;&nbsp;&nbsp;
              <button
                className={styles.banButton}
                onClick={() => {
                  setActionId(row?._id);
                  setUserId(row?.userId?._id);
                  setIsRecoverShow(true);
                  setActionType('rejected');
                  setModalTitle('ISO determination letter rejection alert');
                  setModalMessage(
                    'Are you sure you want to reject this Iso Determination Letter ?'
                  );
                }}
              >
                <FaBan />
              </button>
            </>
          )} */}
        </>
      ),
    },
  ];

  const data = users;

  return (
    <>
      <div className={styles.settings}>
        <div className={styles.btnOuter}>
          <h3>IRS Determination Letter</h3>
        </div>
        <SearchMain onChange={(e) => setSearch(e.target.value)} />
        <DataTable
          columns={columns}
          data={data}
          pagination
          customStyles={customStyles}
        />
      </div>
      <ViewReport
        show={show}
        id={userId}
        setShow={setShow}
        getIsoLettersListing={getIsoLettersListing}
        reportDetail={reportDetail}
      />
      <TooltipGlodal />
    </>
  );
};

export default IsoDeterminationLettersModule;
