import React from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import styles from './userManagement.module.sass';
import User from '../User';
import Roles from '../Roles';
import Permissions from '../Permissions';
const UserRoleManagement = () => {
  return (
    <Tabs
      defaultActiveKey='users'
      id='uncontrolled-tab-example'
      className='mb-3'
    >
      <Tab eventKey='users' title='Users'>
        <div className={styles.outer}>
          <User />
        </div>
      </Tab>
      <Tab eventKey='roles' title='Roles'>
        <div className={styles.outer}>
          <Roles />
        </div>
      </Tab>
      <Tab eventKey='permissions' title='Permissions'>
        <div className={styles.outer}>
          <Permissions />
        </div>
      </Tab>
    </Tabs>
  );
};

export default UserRoleManagement;
