import TextInput from '../../TextInput';
import styles from './PlatFormButton.module.sass';

import cn from 'classnames';
import { useState } from 'react';
import { updateServiceFee } from '../../../apiConnection/etherium.api';
import {
  notifyError,
  notifySuccess,
} from '../../../apiConnection/notification.api';
import { postRequest } from '../../../apiConnection/api';
import { updateServiceFeeApi } from '../../../environment';
import { NavLink } from 'react-router-dom';
import Image from '../../Image';

const ServiceFee = ({ visible, className, close }) => {
  const [serviceFee, setServiceFee] = useState(2.5);
  const handleChange = (value) => {
    setServiceFee(parseFloat(value));
  };
  const handleSubmit = () => {
    console.log('here');
    updateServiceFee(serviceFee).then(async (updated) => {
      if (updated) {
        await postRequest(
          updateServiceFeeApi,
          { serviceFee },
          { token: localStorage.getItem('token') }
        );
        notifySuccess('Successfully updated service fee.');
      } else {
        notifyError('Error occurred while updating service fee.');
      }
    });
  };
  return (
    <div
      className={cn(styles.sidebar, className, { [styles.active]: visible })}
    >
      {/* {visible ? <div className={styles.ServiceFeeDescriptionVisible}>
                Service fee on the platform
            </div> : <div className={styles.ServiceFeeDescriptionNotVisible}>
                Service fee on the platform
            </div>} */}
      <div className={styles.input} onClick={close}>
        {/* <div className={styles.field}>
                    <TextInput
                        className={styles.input}
                        label=""
                        name="serviceFee"
                        type="number"
                        icon="setting"
                        placeholder="2.8%"
                        tooltip=""
                        required
                        handleChange={handleChange}
                    />
                </div> */}
        {/* <NavLink to="/platform-setting" className={styles.item} activeClassName={styles.active}>
                    <Image src="/images/content/platform.svg" className={styles.icon} />
                    <img src="/images/content/platformActive.svg" className={styles.iconActive} />
                    Platform settings
                </NavLink> */}
        {/* {visible ? <button onClick={handleSubmit} className={cn("button-stroke", styles.buttonVisible)}>Save Changes</button>
                    : <button onClick={handleSubmit} className={cn("button-stroke", styles.buttonNotVisible)}>Save Changes</button>} */}
      </div>
    </div>
  );
};

export default ServiceFee;
