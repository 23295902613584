import React, { useEffect, useState } from 'react';
import axios from 'axios';
import cn from 'classnames';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { AiOutlinePlus } from 'react-icons/ai';
import DataTable from 'react-data-table-component';
import styles from './Roles.module.sass';
import TooltipGlodal from '../../components/TooltipGlodal';
import { AiOutlineEdit, AiOutlineDelete } from 'react-icons/ai';
import { rolesListing } from '../../environment';
import {
  notifySuccess,
  notifyError,
} from '../../apiConnection/notification.api';
import DeleteRole from './DeleteRole';
import { customStyles } from '../../helpers/commonStyles';

const ExpandableContent = ({ data }) => {
  return (
    <div className={styles.row}>
      {data?.permissions?.map((data) => (
        <div className={styles.columns}>{data}</div>
      ))}
    </div>
  );
};

const Roles = () => {
  const history = useHistory();
  const [roles, setRoles] = useState('');
  const [deleteId, setDeleteId] = useState('');
  const [show, setShow] = useState(false);

  const getRoleListing = async () => {
    const getRoles = await axios.get(rolesListing, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });

    if (getRoles?.data) {
      setRoles(getRoles?.data?.data);
    }
  };
  useEffect(() => {
    getRoleListing();
  }, []);

  const columns = [
    {
      name: 'Role Name',
      selector: (row) => row.roleName,
    },
    {
      name: 'Created Date',
      selector: (row) => moment(row.createdAt).format('MMMM, DD, YYYY'),
    },
    {
      name: 'Actions',
      cell: (row) => (
        <>
          <button
            className={styles.editButton}
            onClick={() =>
              history.push(`edit-role/${row?._id}`, {
                row,
              })
            }
          >
            <AiOutlineEdit />
          </button>{' '}
          &nbsp;&nbsp;&nbsp;
          <button
            className={styles.deleteButton}
            onClick={() => {
              setDeleteId(row?._id);
              setShow(true);
            }}
          >
            <AiOutlineDelete />
          </button>
        </>
      ),
    },
  ];

  const data = roles;

  const expandableRowsComponent = ({ data }) => (
    <ExpandableContent data={data} />
  );

  return (
    <>
      <div className={styles.settings}>
        <div className={styles.btnOuter}>
          <h3>Roles</h3>
          <button
            className={cn('button', styles.addButton)}
            type='button'
            onClick={() => history.push('/create-role')}
          >
            <AiOutlinePlus />
          </button>
        </div>

        <DataTable
          columns={columns}
          data={data}
          pagination
          expandableRows
          expandableRowsComponent={expandableRowsComponent}
          customStyles={customStyles}
        />
      </div>
      <DeleteRole
        show={show}
        id={deleteId}
        setShow={setShow}
        getPermissionListing={getRoleListing}
      />
      <TooltipGlodal />
    </>
  );
};

export default Roles;
