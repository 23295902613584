import React, { useState, Fragment } from "react";
import { Modal, Button } from "react-bootstrap";
import { Radio, Space, RadioChangeEvent } from "antd";
import Classes from "./ReportModal.module.sass";
import { reportOrganization } from "../../environment";
import {
  notifySuccess,
  notifyError,
} from "../../apiConnection/notification.api";
import axios from "axios";

const radioOptions = [
  {
    id: 1,
    title: "Spam",
    value: "Spam",
    default: true,
  },
  {
    id: 2,
    title: "Fraud",
    value: "Fraud",
  },
  {
    id: 3,
    title: "Child sexual exploitation or abuse",
    value: "Child sexual exploitation or abuse",
  },
  {
    id: 4,
    title: "Terrorism or violent extremism",
    value: "Terrorism or violent extremism",
  },
  {
    id: 5,
    title: "Hate speach",
    value: "Hate speach",
  },
  {
    id: 6,
    title: "Imminent harm to person or property",
    value: "Imminent harm to person or property",
  },
  {
    id: 7,
    title: "Non-consensual intimate imagery",
    value: "Non-consensual intimate imagery",
  },
  {
    id: 8,
    title: "Self-harm or suicide",
    value: "Self-harm or suicide",
  },
  {
    id: 9,
    title: "Harrasment or bullying",
    value: "Harrasment or bullying",
  },
  {
    id: 10,
    title: "Defamation, impersonations, false information",
    value: "Defamation, impersonations, false information",
  },
  {
    id: 11,
    title: "Sexually inappropriate",
    value: "Sexually inappropriate",
  },
  {
    id: 12,
    title: "Content infringement",
    value: "Content infringement",
  },
  {
    id: 13,
    title: "Malware or viruses",
    value: "Malware or viruses",
  },
  {
    id: 14,
    title: "Others",
    value: "Others",
  },
];
const ReportModal = ({
  showModal,
  setShowModal,
  reportId,
  setReportId,
  reportType,
  setReportType,
}) => {
  const [value, setValue] = useState(null);
  const [reportReason, setReportReason] = useState("");
  const [selectedOption, setSelectedOption] = useState({
    id: "",
    title: "",
    value: "",
  });
  const [error, setError] = useState('');
  const userInfo = JSON.parse(localStorage.getItem("user"));

  // Reset the modal state when it's opened
  React.useEffect(() => {
    if (showModal) {
      setValue(null);
      setReportReason("");
      setSelectedOption({
        id: "",
        title: "",
        value: "",
      });
      setError('')
    }
  }, [showModal]);

  const handleClose = () => {
    setShowModal(false);
    // Reset the radio selection
    setValue(null); // Clear the selected radio option
    setSelectedOption({
      id: "",
      title: "",
      value: "",
    });
    setReportReason("");
  };

  const onChange = (e) => {
    const selectedRadioOption = radioOptions.find(
      (option) => option.id === e.target.value
    );

    setValue(e.target.value);
    setSelectedOption(selectedRadioOption);
    setReportReason(selectedRadioOption?.value);
    setError('')

  };

  const handleReasonState = (e) => {
    setReportReason(e?.target?.value);
  };

  const reportUserOROrganization = async () => {
    try {
      if (!reportReason) {
        setError('Report reason is required.')
        return;
      }
      if(selectedOption?.id === 14){
        if(reportReason==='Others'){
          setError('Report reason is required.')
          return;
        }
        if(reportReason?.length<50){
          return;
        }
        if(reportReason.length>600){
          return;
        }
      }

      const response = await axios.post(
        `${reportOrganization}`,
        {
          organizationId: null,
          reporterEmail: userInfo?.email,
          reportType: reportType,
          reason: reportReason,
          reportedBy: userInfo?._id,
          reportedTo: reportId,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response?.data) {
        notifySuccess(`${reportType} reported successfully.`);
        handleClose();
      }
    } catch (error) {
      notifyError(error?.response?.data?.message);
    }
  };

  return (
    <div>
      <Modal
        className={Classes.actionModal}
        show={showModal}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <h3 style={{ color: "#000" }}>Report</h3>
        </Modal.Header>
        <Modal.Body>
          <form
            autoComplete="off"
            className={`${Classes.modalContent} mt-8 space-y-6`}
          >
            <div className={`${Classes.addContent} py-2`}>
              <div className="mb-3">
                <p className={Classes.labelTxt}>
                  <b>Report Reason</b>
                </p>
                <Radio.Group onChange={onChange} value={value}>
                  <Space direction="vertical">
                    {radioOptions.map((radioOption) => (
                      <Radio key={radioOption.id} value={radioOption.id}>
                        {radioOption.title}
                      </Radio>
                    ))}
                  </Space>
                </Radio.Group>

                {value === 14 ? (
                  <div className='mb-3 mt-4'>
                    <textarea
                      name='reason'
                      placeholder='Enter report reason'
                      onChange={(e) => {
                        handleReasonState(e);  // Formik's onChange handler
                        const reason = e.target.value;
                        if (reason.length < 50) {
                          setError('Reason should have at least 50 characters.');
                        } else if (reason.length > 600) {
                          setError('Maximum 600 characters allowed.');
                        } else {
                          setError('');
                        }
                      }}
                      className={Classes.metaInfo}
                      rows={3}
                    ></textarea>
                  </div>
                ) : null}

                {error ? (
                  <span
                    className='mt-1 d-block'
                    size={'small'}
                    style={{ color: 'red' }}
                  >
                    <Fragment>{error}</Fragment>
                  </span>
                ) : (
                  ''
                )}
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-danger" onClick={handleClose}>
            Close
          </Button>
          <Button onClick={reportUserOROrganization}>Report</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ReportModal;

