import React from 'react';
import styles from './reports.module.sass';
import CausesModule from './Causes';
const Reports = () => {
  return (
    <div className={styles.outer}>
      <CausesModule />
    </div>
  );
};

export default Reports;
