import React from 'react';
import styles from './reports.module.sass';
import IsoDeterminationLettersModule from './IsoDeterminationLetters';

const IsoDeterminationLetters = () => {
  return (
    <div className={styles.outer}>
      <IsoDeterminationLettersModule />
    </div>
  );
};

export default IsoDeterminationLetters;
