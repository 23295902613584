import { CoinbaseWallet } from '@web3-react/coinbase-wallet';
import { Web3ReactHooks } from '@web3-react/core';
import { MetaMask } from '@web3-react/metamask';
import { Network } from '@web3-react/network';
import type { Connector } from '@web3-react/types';
import { WalletConnect as WalletConnectV2 } from '@web3-react/walletconnect-v2';
import { initializeConnector } from '@web3-react/core';
import { WalletConnect } from '@web3-react/walletconnect-v2';
import type { AddEthereumChainParameter } from '@web3-react/types';
import {
  REACT_APP_POCKET_KEY,
  REACT_APP_INFURA_KEY,
  REACT_APP_WALLETCONNECT_PROJECT_ID,
} from './environment';

const infuraKey = REACT_APP_INFURA_KEY;
const alchemyKey = REACT_APP_INFURA_KEY;
const pocketNetwork = REACT_APP_POCKET_KEY;

export const chainIds = {
  polygon: '137',
  mumbai: '80001',
};

const ETH: AddEthereumChainParameter['nativeCurrency'] = {
  name: 'Ether',
  symbol: 'ETH',
  decimals: 18,
};

const MATIC: AddEthereumChainParameter['nativeCurrency'] = {
  name: 'Matic',
  symbol: 'MATIC',
  decimals: 18,
};

interface BasicChainInformation {
  chainId: string;
  urls: string[];
  publicUrls: string[];
  name: string;
}

interface ExtendedChainInformation extends BasicChainInformation {
  nativeCurrency: AddEthereumChainParameter['nativeCurrency'];
  blockExplorerUrls: AddEthereumChainParameter['blockExplorerUrls'];
}

export const CHAINS: {
  [chainId: number]: BasicChainInformation | ExtendedChainInformation;
} = {
  1: {
    chainId: '1',
    urls: [
      infuraKey ? `https://mainnet.infura.io/v3/${infuraKey}` : '',
      alchemyKey ? `https://eth-mainnet.g.alchemy.com/v2/${alchemyKey}` : '',
      pocketNetwork
        ? `https://eth-mainnet.gateway.pokt.network/v1/lb/${pocketNetwork}`
        : '',
      'https://rpc.ankr.com/eth',
      'https://cloudflare-eth.com',
    ].filter(Boolean),
    publicUrls: ['https://rpc.ankr.com/eth'].filter(Boolean),
    name: 'Mainnet',
    nativeCurrency: ETH,
    blockExplorerUrls: ['https://etherscan.io'],
  },
  // Polygon
  137: {
    chainId: '137',
    urls: [
      infuraKey
        ? `https://polygon-mainnet.infura.io/v3/${REACT_APP_INFURA_KEY}`
        : '',
      alchemyKey
        ? `https://polygon-mainnet.g.alchemy.com/v2/${alchemyKey}`
        : '',
      pocketNetwork
        ? `https://poly-mainnet.gateway.pokt.network/v1/lb/${pocketNetwork}`
        : '',
      'https://polygon-rpc.com',
    ].filter(Boolean),
    publicUrls: ['https://polygon-rpc.com'].filter(Boolean),
    name: 'Polygon',
    nativeCurrency: MATIC,
    blockExplorerUrls: ['https://polygonscan.com'],
  },
  80001: {
    chainId: '80001',
    urls: [
      infuraKey
        ? `https://polygon-mumbai.infura.io/v3/${REACT_APP_INFURA_KEY}`
        : '',
      alchemyKey ? `https://polygon-mumbai.g.alchemy.com/v2/${alchemyKey}` : '',
      'https://rpc-mumbai.maticvigil.com',
    ].filter(Boolean),
    publicUrls: ['https://rpc-mumbai.maticvigil.com'].filter(Boolean),
    name: 'Polygon Mumbai',
    nativeCurrency: MATIC,
    blockExplorerUrls: ['https://mumbai.polygonscan.com'],
  },
};

export const URLS: { [chainId: number]: string[] } = Object.keys(
  CHAINS
).reduce<{ [chainId: number]: string[] }>((accumulator, chainId) => {
  const validURLs: string[] = CHAINS[Number(chainId)].urls;

  if (validURLs.length) {
    accumulator[Number(chainId)] = validURLs;
  }

  return accumulator;
}, {});

export const CHAINIDs: { [chainId: number]: string } = Object.keys(
  CHAINS
).reduce<{ [chainId: number]: string }>((accumulator, chainId) => {
  const validCHAINIDs: string = CHAINS[Number(chainId)].chainId;

  if (validCHAINIDs) {
    accumulator[Number(chainId)] = validCHAINIDs;
  }

  return accumulator;
}, {});

export const [network, networkHooks] = initializeConnector<Network>(
  (actions) => new Network({ actions, urlMap: URLS })
);
export const [metaMask, metaMaskHooks] = initializeConnector<MetaMask>(
  (actions) => new MetaMask({ actions })
);
export const [coinbaseWallet, coinbaseWalletHooks] =
  initializeConnector<CoinbaseWallet>(
    (actions) =>
      new CoinbaseWallet({
        actions,
        options: {
          url: URLS[1][0],
          appName: 'Web3-Boilerplate',
        },
      })
  );
const [mainnet, ...optionalChains] = Object.keys(CHAINS).map(Number);

export const [walletConnect, walletConnectHooks] =
  initializeConnector<WalletConnect>(
    (actions) =>
      new WalletConnect({
        actions,
        options: {
          projectId: REACT_APP_WALLETCONNECT_PROJECT_ID ?? '',
          chains: [mainnet],
          optionalChains,
          showQrModal: true,
        },
      })
  );

export function getName(connector: Connector) {
  if (connector instanceof MetaMask) return 'MetaMask';
  if (connector instanceof WalletConnectV2) return 'WalletConnect';
  if (connector instanceof CoinbaseWallet) return 'Coinbase Wallet';
  if (connector instanceof Network) return 'Network';
  return 'Unknown';
}

export const connectors: [
  MetaMask | WalletConnect | CoinbaseWallet | Network,
  Web3ReactHooks
][] = [
  [metaMask, metaMaskHooks],
  [walletConnect, walletConnectHooks],
  [coinbaseWallet, coinbaseWalletHooks],
  [network, networkHooks],
];

// export default connectors;
