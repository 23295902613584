import React from "react";
import styles from "./CustumToggleButton.module.sass"
import cn from "classnames";

const CustumToggleButton = ({check,onchange,id,...props}) => {
    
    return (
      <>
        <label className={styles.switch}>
        <input type="checkbox"
        id={id} 
        checked={check}
				onChange={onchange}
        {...props}
         />
        <span className={cn('roundToggle', styles.slider)}></span>
        </label>
        
      </>
    );
  };
  
  export default CustumToggleButton;