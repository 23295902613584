import * as Yup from 'yup';

const FILE_SIZE = 400 * 400;
const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];

export const EditProfileSchema = Yup.object().shape({
  firstName: Yup.string(),
  lastName: Yup.string(),
  Email: Yup.string().email('Invalid email format'),
  profilePicture: Yup.mixed()
    .nullable()
    .notRequired()
    .test(
      'FILE_FORMAT',
      'Uploaded file has unsupported format.',
      (value) => !value || (value && SUPPORTED_FORMATS.includes(value.type))
    ),
  // .test(
  //     'FILE_SIZE',
  //     'Uploaded file is too big.',
  //     (value) => !value || (value && value.size <= FILE_SIZE),
  // ),
});

export const ChangePasswordSchema = Yup.object().shape({
  oldPassword: Yup.string().required('Old password is required.'),
  newPassword: Yup.string()
    .required('No password provided.')
    .min(8, 'Password is too short - should be 8 chars minimum.'),
  confirmPassword: Yup.string()
    .required('Please confirm your password.')
    .oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),
});
