import React, { useState } from 'react';
import axios from 'axios';
import cn from 'classnames';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import { ThreeDots } from 'react-loader-spinner';

import TooltipGlodal from '../../components/TooltipGlodal';
import styles from './Permission.module.sass';
import TextInput from '../../components/TextInput';
import { PermissionSchema } from './PermissionSchema';
import { createPermission } from '../../environment';
import {
  notifySuccess,
  notifyError,
} from '../../apiConnection/notification.api';

const CreatePermission = () => {
  const history = useHistory();
  const [loading, setIsLoading] = useState(false);
  const {
    dirty,
    values,
    handleChange,
    handleSubmit,
    handleBlur,
    touched,
    errors,
    resetForm,
    isValid,
  } = useFormik({
    onSubmit: async (values, { setErrors }) => {
      setIsLoading(true);

      try {
        const saveData = await axios.post(
          createPermission,
          {
            permissionName: values.permissionName,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          }
        );

        if (saveData) {
          setErrors({});
          setIsLoading(false);
          notifySuccess(saveData?.data?.message);
          resetForm();
          history.push('/permissions');
        }
      } catch (error) {
        console.log('error :', error);
        notifyError(error?.response?.data?.message);
        setIsLoading(false);
      }
    },
    initialValues: {
      permissionName: '',
    },
    validationSchema: PermissionSchema,
    validateOnBlur: true,
    validateOnMount: false,
    enableReinitialize: true,
  });
  return (
    <>
      <div className={styles.settings}>
        <div className={styles.fieldset}>
          <TextInput
            className={styles.field}
            label='Permission'
            name='permissionName'
            type='text'
            tooltip='Required.'
            placeholder='Enter permission name'
            required
            value={values.permissionName}
            onChange={handleChange}
            onBlur={handleBlur}
            isError={touched.permissionName && !!errors.permissionName}
            errorMessage={errors.permissionName}
          />
        </div>
        <div className={styles.buttonsParent}>
          <button
            className={cn('button', styles.button)}
            style={{ marginTop: 20 }}
            onClick={() => history.push('/permissions')}
          >
            Back
          </button>
          <button
            onClick={handleSubmit}
            disabled={!dirty || !isValid || loading}
            className={cn('button', styles.button)}
            style={{ marginTop: 20 }}
          >
            {loading ? (
              <ThreeDots
                height='30'
                width='30'
                radius='9'
                color='#2A85FF'
                ariaLabel='three-dots-loading'
                wrapperStyle={{}}
                wrapperClassName=''
                visible={true}
              />
            ) : (
              'Save'
            )}
          </button>
        </div>
      </div>
      <TooltipGlodal />
    </>
  );
};

export default CreatePermission;
