import React, { useState } from 'react';
import cn from 'classnames';
import styles from './Overview.module.sass';
import Item from './Item';
import Card from '../Card';
import Dropdown from '../Dropdown';

const intervals = [
  'All time',
  'Last 7 days',
  'Last month',
  'Last 3 months',
  'Last 6 months',
  'Last year',
];

const Overview = ({ className, nav, title, classTitle, setDays }) => {
  const [sorting, setSorting] = useState(intervals[0]);
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <Card
      className={cn(styles.card, className)}
      title={title}
      classTitle={classTitle}
      head={
        setDays ? (
          <Dropdown
            className={styles.dropdown}
            classDropdownHead={styles.dropdownHead}
            value={sorting}
            setValue={setSorting}
            setDays={setDays}
            options={intervals}
            small
          />
        ) : (
          <></>
        )
      }
    >
      <div className={styles.overview}>
        <div className={styles.nav}>
          {nav.map((x, index) => (
            <div className={styles.outerDiv} key={index}>
              {x.map((y, index) => (
                <Item
                  className={cn(styles.item, {
                    [styles.active]: index === activeIndex,
                  })}
                  key={index}
                  onActive={() => setActiveIndex(index)}
                  item={y}
                />
              ))}
            </div>
          ))}
        </div>
      </div>
    </Card>
  );
};

export default Overview;
