import React, { useState } from 'react';
import cn from 'classnames';
import styles from './Login.module.sass';
import Item from '../Item';
import TextInput from '../../../components/TextInput';
import { useFormik } from 'formik';
import { ThreeDots } from 'react-loader-spinner';
import { ChangePasswordSchema } from '../ProfileInformation/EditProfileSchema';
import { useUpdatePassword } from '../../../hooks/editProfileHooks';
import {
  notifySuccess,
  notifyError,
} from '../../../apiConnection/notification.api';

const Login = ({ className }) => {
  const [disableForm, setDisableForm] = useState(false);
  const [loading, setIsLoading] = useState(false);
  const { mutate: updatePasswordMutation } = useUpdatePassword();

  const {
    dirty,
    values,
    handleChange,
    handleSubmit,
    handleBlur,
    touched,
    errors,
    resetForm,
    isValid,
  } = useFormik({
    onSubmit: async (values, { setErrors }) => {
      setDisableForm(true);
      setIsLoading(true);
      const data = {
        oldPassword: values.oldPassword,
        password: values.newPassword,
        confirmPassword: values.confirmPassword,
      };
      updatePasswordMutation(data, {
        onSettled: () => {
          setErrors({});
          setDisableForm(false);
          setIsLoading(false);
          notifySuccess('Password changed successfully.');
          resetForm();
        },
      });
    },
    initialValues: {
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
    validationSchema: ChangePasswordSchema,
    validateOnBlur: true,
    validateOnMount: false,
    enableReinitialize: true,
  });

  return (
    <Item
      className={cn(styles.card, className)}
      title='Update Password'
      classTitle='title-purple'
    >
      <div className={styles.fieldset}>
        <TextInput
          className={styles.field}
          label='Old password'
          name='oldPassword'
          type='password'
          tooltip='Maximum 100 characters. No HTML or emoji allowed'
          required
          value={values.oldPassword}
          onChange={handleChange}
          onBlur={handleBlur}
          isError={touched.oldPassword && !!errors.oldPassword}
          errorMessage={errors.oldPassword}
        />
        <div className={styles.row}>
          <TextInput
            className={styles.field}
            label='New password'
            name='newPassword'
            type='password'
            tooltip='Maximum 100 characters. No HTML or emoji allowed'
            required
            value={values.newPassword}
            onChange={handleChange}
            onBlur={handleBlur}
            isError={touched.newPassword && !!errors.newPassword}
            errorMessage={errors.newPassword}
          />
          <TextInput
            className={styles.field}
            label='Confirm new password'
            name='confirmPassword'
            type='password'
            tooltip='Maximum 100 characters. No HTML or emoji allowed'
            required
            value={values.confirmPassword}
            onChange={handleChange}
            onBlur={handleBlur}
            isError={touched.confirmPassword && !!errors.confirmPassword}
            errorMessage={errors.confirmPassword}
          />
        </div>
        <button
          onClick={handleSubmit}
          disabled={!dirty || disableForm || !isValid || loading}
          className={cn('button-stroke', styles.button)}
        >
          {loading ? (
            <ThreeDots
              height='30'
              width='30'
              radius='9'
              color='#2A85FF'
              ariaLabel='three-dots-loading'
              wrapperStyle={{}}
              wrapperClassName=''
              visible={true}
            />
          ) : (
            'Update password'
          )}
        </button>
      </div>
    </Item>
  );
};

export default Login;
