import React, { Fragment, useEffect, useState } from "react";
import cn from "classnames";
import styles from "./ServiceFee.module.sass";
import Card from "../../../components/Card";
import Image from "../../../components/Image";
import TextInput from "../../../components/TextInput";
import axios from "axios";

import {
  notifyError,
  notifySuccess,
} from "../../../apiConnection/notification.api";
import {
  createOrUpdatePlatformSettings,
} from "../../../environment";

import * as Yup from "yup";
import { Formik } from "formik";
import { connectWithMetamask, getConnectedAddress } from "../../../web3";
import { transferOwnership,updatePlatformFee } from "../../../contracts/marketPlace";
import { decimalnumbervalidator } from "../../../helpers";
const ServiceFee = ({ settings,fetchPlatFormSettings }) => {
  const [walletAddress, setAddress] = useState(settings[0]?.walletAddress);
  useEffect(()=>{
    if(settings[0]?.walletAddress){
      setAddress(settings[0]?.walletAddress)
    }

  },[settings[0]?.walletAddress])
  const handleSettingsSubmit = async (values) => {
    try {
      const settingsResponse = await axios.post(
        createOrUpdatePlatformSettings,
        values,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      if (settingsResponse?.data?.success) {
        fetchPlatFormSettings()
        notifySuccess("Platform settings updated successfully.");
      } else {
        notifyError("Error occurred while updating platform settings.");
      }

    } catch (error) {
      console.log("error :", error);
      notifyError(`${error?.message}`);
    }
  };
  const checkAdminWalletConnected = async (connectedWallet = null) => {
    connectedWallet = await getConnectedAddress();
    if (!connectedWallet || connectedWallet !== settings[0].walletAddress) {
      connectedWallet = await connectWithMetamask();
    }
    if (connectedWallet !== settings[0].walletAddress) {
      return false;
    }
    return connectedWallet;
  };
  const handleChangeWallet = async (address,platformFee) => {
    try {
      const connected = await checkAdminWalletConnected();
      if (!connected) {
        return notifyError(`Please connect with admin address ${settings[0]?.walletAddress?.slice(0,9)+"..."+settings[0]?.walletAddress?.slice(-9)}`);
      }
      if(address!== settings[0]?.walletAddress){
        const receipt = await transferOwnership(address);
        setAddress(connected);
        return receipt;
      }
      if(platformFee!== parseFloat(settings[0]?.platformFee)){
        const receipt = await updatePlatformFee((platformFee*100));
        setAddress(connected);
        return receipt;
      }
      return connected
   
    } catch (err) {
      console.log(err, "error==>");
    }
  };

  return (
    <>
      <div className={styles.row}>
        <div className={styles.col}>
          <Card title="Platform Settings" classTitle="title-red">
            <Formik
              enableReinitialize={true}
              initialValues={{
                platformFee: +settings[0]?.platformFee || "",
                royaltyFee: +settings[0]?.royaltyFee || "",
                walletAddress:walletAddress,
              }}
              validationSchema={Yup.object().shape({
                platformFee: Yup.number()
                  .min(1,"Platform fee must be greater than or equal to 1 and less than or equal to 30").max(30,'Platform fee must be greater than or equal to 1 and less than or equal to 30')
                  .required("Platform fee is required."),
                royaltyFee: Yup.number()
                  .min(1,"Royalty fee must be greater than or equal to 1 and less than or equal to 30")
                  .max(30,"Royalty fee must be greater than or equal to 1 and less than or equal to 30")
                  .required("Royalty fee is required."),
                  walletAddress: Yup.string()
                  .matches(/^0x[a-fA-F0-9]{40}$/, 'Invalid wallet address.')
                  .required('Wallet address is required.'),
              })}
            
              onSubmit={async (values, { resetForm }) => {
                  const res = await handleChangeWallet(
                    values?.walletAddress,
                    values?.platformFee
                  );
                  if (!res) {
                    return;
                  }
                handleSettingsSubmit(values);
              }}
              textAlign="center"
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values,
              }) => (
                <form
                  onSubmit={handleSubmit}
                  className="mt-8 space-y-6"
                  noValidate
                  autoComplete="off"
                >
                  <div className={cn("generalRow")}>
                    <div className={cn("labelRow")}>
                      <label className={cn("labelStyle")}>Platform Fee</label>
                    </div>
                    <div className="inputRow">
                      <TextInput
                        className={styles.input}
                        name="platformFee"
                        type="text"
                        // icon='setting'
                        placeholder="2.8%"
                        tooltip=""
                        onBlur={handleBlur}
                        onChange={handleChange}
                        onKeyDown={(e) => decimalnumbervalidator(e,true,2)}
                        value={values.platformFee}
                      />
                      <span
                        className="mt-1 d-block"
                        size={"small"}
                        style={{ color: "red" }}
                      >
                        {errors.platformFee && touched.platformFee ? (
                          <Fragment>{errors.platformFee}</Fragment>
                        ) : null}
                      </span>
                    </div>
                  </div>

                  <div
                    className={cn("generalRow")}
                    style={{ marginTop: "20px" }}
                  >
                    <div className={cn("labelRow")}>
                      <label className={cn("labelStyle")}>Royalty Fee</label>
                    </div>
                    <div className="inputRow">
                      <TextInput
                        className={styles.input}
                        name="royaltyFee"
                        type="text"
                        // icon='setting'
                        placeholder="2.8%"
                        tooltip=""
                        onBlur={handleBlur}
                        onChange={handleChange}
                        onKeyDown={(e) => decimalnumbervalidator(e,true,2)}
                        value={values.royaltyFee}
                      />
                      <span
                        className="mt-1 d-block"
                        size={"small"}
                        style={{ color: "red" }}
                      >
                        {errors.royaltyFee && touched.royaltyFee ? (
                          <Fragment>{errors.royaltyFee}</Fragment>
                        ) : null}
                      </span>
                    </div>
                  </div>

                  <div
                    className={cn("generalRow")}
                    style={{ marginTop: "20px" }}
                  >
                    <div className={cn("labelRow")}>
                      <label className={cn("labelStyle")}>Wallet address</label>
                    </div>
                    <div className="inputRow">
                      <TextInput
                        className={styles.input}
                        name="walletAddress"
                        type="text"
                        // icon='setting'
                        // placeholder='2.8%'
                        tooltip=""
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values?.walletAddress}
                      />
                      <span
                        className="mt-1 d-block"
                        size={"small"}
                        style={{ color: "red" }}
                      >
                        {errors.walletAddress && touched.walletAddress ? (
                          <Fragment>{errors.walletAddress}</Fragment>
                        ) : null}
                      </span>
                    </div>
                  </div>
                  <button className={cn("transparentBtn")} type="submit">
                    Apply Settings
                  </button>
                </form>
              )}
            </Formik>
          </Card>
        </div>
      </div>
    </>
  );
};

export default ServiceFee;
