import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import cn from 'classnames';
import { ethers } from 'ethers';
import styles from './SignIn.module.sass';
import { use100vh } from 'react-div-100vh';
import { Link } from 'react-router-dom';
import Image from '../../components/Image';
import { connectors, getName } from '../../WalletConnection';

const ConnectWallet = () => {
  const history = useHistory();
  const heightWindow = use100vh();

  const [activeConnector, setActiveConnector] = useState(null);
  const [activeAccount, setActiveAccount] = useState(null);

  const connectWallet = (connector) => {
    if (connector === activeConnector) return; // Don't connect if already connected with the same connector
    setActiveConnector(connector);
    activateConnector(connector);
  };

  const activateConnector = async (connector) => {
    try {
      if (connector === null) return;

      // Your connection logic here
      await connector.activate(); // You may need to call the connector's activate method

      // Access the connected account
      const provider = new ethers.providers.Web3Provider(connector.provider);
      const signer = provider.getSigner();
      const account = await signer.getAddress();

      setActiveAccount(account);
    } catch (error) {
      console.error('Error connecting to the wallet:', error);
    }
  };

  const requestAccount = async () => {
    console.log('Requesting account...');

    if (window.ethereum) {
      console.log('Metamask detected');

      try {
        const accounts = await window.ethereum.request({
          method: 'eth_requestAccounts',
        });

        if (accounts) {
          console.log('accounts =>', accounts);
          localStorage.setItem('walletAddress', accounts[0]);
          history.push({
            pathname: '/sign-in',
            state: { walletAddress: accounts[0] }, // Pass your state object here
          });
        }
      } catch (error) {
        console.log('error =>', error);
      }
    } else {
      console.log('Metamask not detected');
    }
  };

  const names = getName();
  return (
    <div className={styles.login} style={{ minHeight: heightWindow }}>
      <div className={styles.wrapper}>
        <div className={styles.body}>
          {/* <button
            onClick={requestAccount}
            className={cn('button', styles.button)}
          >
            Connect Wallet
          </button> */}
          {activeConnector ? (
            <p>Connected with {getName(activeConnector)}</p>
          ) : (
            <ul>
              {connectors.map(([connector, hooks]) => (
                <li key={getName(connector)}>
                  <button
                    onClick={() => connectWallet(connector)}
                    className={cn('button mb-2', styles.button)}
                  >
                    Connect with {getName(connector)}
                  </button>{' '}
                </li>
              ))}
            </ul>
          )}
          {activeAccount && <p>Connected Account: {activeAccount}</p>}
        </div>
      </div>
    </div>
  );
};

export default ConnectWallet;
