import React, { useEffect, useState } from 'react';
import moment from 'moment';
import styles from './Permission.module.sass';
import TooltipGlodal from '../../components/TooltipGlodal';
import DataTable from 'react-data-table-component';
import { AiOutlinePlus } from 'react-icons/ai';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { permissionListing } from '../../environment';
import { AiOutlineEdit, AiOutlineDelete } from 'react-icons/ai';
import DeletePermission from './DeletePermission';
import {
  notifySuccess,
  notifyError,
} from '../../apiConnection/notification.api';
import { customStyles } from '../../helpers/commonStyles';

const PermissionsListing = () => {
  const history = useHistory();
  const [permissions, setPermissions] = useState([]);
  const [deleteId, setDeleteId] = useState('');
  const [show, setShow] = useState(false);

  const getPermissionListing = async () => {
    try {
      const getPermissions = await axios.get(permissionListing, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (getPermissions?.data?.data?.length) {
        setPermissions(getPermissions?.data?.data);
      }
    } catch (error) {
      notifyError(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    getPermissionListing();
  }, []);

  const columns = [
    {
      name: 'Permission Name',
      selector: (row) => row.permissionName,
    },
    {
      name: 'Created Date',
      selector: (row) => moment(row.createdAt).format('MMMM, DD, YYYY'),
    },
    {
      name: 'Actions',
      cell: (row) => (
        <>
          <button
            className={styles.editButton}
            onClick={() =>
              history.push(`edit-permission/${row?._id}`, {
                permissionName: row?.permissionName,
              })
            }
          >
            <AiOutlineEdit />
          </button>
          &nbsp;&nbsp;
          <button
            className={styles.deleteButton}
            onClick={() => {
              setDeleteId(row?._id);
              setShow(true);
            }}
          >
            <AiOutlineDelete />
          </button>
        </>
      ),
    },
  ];

  const data = permissions;

  return (
    <>
      <div className={styles.settings}>
        <div className={styles.btnOuter}>
          <h3>Permissions</h3>
          <button
            className={styles.addButton}
            type='button'
            onClick={() => history.push('/create-permission')}
          >
            <AiOutlinePlus />
          </button>
        </div>
        <DataTable
          columns={columns}
          data={data}
          pagination
          customStyles={customStyles}
        />
      </div>
      <DeletePermission
        show={show}
        id={deleteId}
        setShow={setShow}
        getPermissionListing={getPermissionListing}
      />

      <TooltipGlodal />
    </>
  );
};

export default PermissionsListing;
