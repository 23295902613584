import React, { useState } from "react";
import styles from "./Sidebar.module.sass";
import { Link, NavLink, useHistory } from "react-router-dom";
import cn from "classnames";
import Icon from "../Icon";
import Theme from "../Theme";
import Dropdown from "./Dropdown";
import Help from "./Help";
import Image from "../Image";
import ServiceFee from "./PlatFormButton";
import { TbUserShield } from "react-icons/tb";

const navigation = [
  {
    title: "Home",
    iconPath: "/images/content/home.svg",
    iconPathActive: "/images/content/homeActive.svg",
    url: "/",
  },
  {
    title: "Users Management",
    iconPath: "/images/content/user.svg",
    iconPathActive: "/images/content/userActive.svg",
    url: "/user-role-management",
  },
  {
    title: "Organizations Management",
    iconPath: "/images/content/user.svg",
    iconPathActive: "/images/content/userActive.svg",
    url: "/organizations",
  },
  {
    title: "IRS Determination Letters",
    iconPath: "/images/content/user.svg",
    iconPathActive: "/images/content/userActive.svg",
    url: "/irs-determination-letters",
  },
  {
    title: "Fiscal Sponsorship Letters",
    iconPath: "/images/content/user.svg",
    iconPathActive: "/images/content/userActive.svg",
    url: "/fiscal-sponsorship-letters",
  },
  {
    title: "Incident Reports",
    iconPath: "/images/content/reports.svg",
    iconPathActive: "/images/content/reportsActive.svg",
    url: "/reports",
  },
  {
    title: "Analytics",
    iconPath: "/images/content/platform.svg",
    iconPathActive: "/images/content/platformActive.svg",
    url: "/analytics",
  },
  {
    title: "Causes",
    iconPath: "/images/content/listings.svg",
    iconPathActive: "/images/content/listingsActive.svg",
    url: "/causes",
  },
  // {
  //   title: 'Customers',
  //   slug: 'customers',
  //   icon: 'profile-circle',
  //   dropdown: [
  //     {
  //       title: 'Overview',
  //       url: '/customers/overview',
  //     },
  //     {
  //       title: 'Customer list',
  //       url: '/customers/customer-list',
  //     },
  //   ],
  // },
];
// 
const Sidebar = ({ className, onClose }) => {
  const [visibleHelp, setVisibleHelp] = useState(false);
  const [visible, setVisible] = useState(false);
  const history = useHistory();

  return (
    <>
      <div
        className={cn(styles.sidebar, className, { [styles.active]: visible })}
      >
        <button className={styles.close} onClick={onClose}>
          <Icon name="close" size="24" />
        </button>
        <Link className={styles.logo} to="/" onClick={onClose}>
          <img
            className={styles.pic}
            src="/images/logo-dark.svg"
            // src="/images/logo-dark.svg"
            alt="logo"
          />
          {/* <Image
            className={styles.mobileLogo}
            src='/images/logoMobile.svg'
            srcDark='/images/logoMobile.svg'
            alt='Core'
          /> */}
        </Link>
        <div className={styles.menu}>
          {navigation.map((x, index) =>
            x.url ? (
              <NavLink
                className={styles.item}
                activeClassName={styles.active}
                to={x.url}
                key={index}
                exact
                onClick={onClose}
              >
                <img src={x.iconPath} className={styles.icon} />
                <img src={x.iconPathActive} className={styles.iconActive} />
                {x.title}
              </NavLink>
            ) : (
              <Dropdown
                className={styles.dropdown}
                visibleSidebar={visible}
                setValue={setVisible}
                key={index}
                item={x}
                onClose={onClose}
              />
            )
          )}
        </div>
        <button className={styles.toggle} onClick={() => setVisible(!visible)}>
          <Icon name="arrow-right" size="24" />
          <Icon name="close" size="24" />
        </button>
        <div className={styles.foot}>
          {/* <button className={styles.link} onClick={() => setVisibleHelp(true)}> */}
          <button
            className={styles.link}
            onClick={() => history.push("/platform-setting")}
          >
            <Icon name="help" size="24" />
            Platform Settings
            {/* <div className={styles.counter}>8</div> */}
          </button>
          <ServiceFee visible={visible} close={onClose} />
          {/* <Theme className={styles.theme} visibleSidebar={visible} /> */}
        </div>
      </div>
      {/* <Help
        visible={visibleHelp}
        setVisible={setVisibleHelp}
        onClose={onClose}
      />
      <div
        className={cn(styles.overlay, { [styles.active]: visible })}
        onClick={() => setVisible(false)}
      ></div> */}
    </>
  );
};

export default Sidebar;
