import React, { useEffect, useState } from 'react';
import axios from 'axios';
import cn from 'classnames';
import moment from 'moment';
import DataTable from 'react-data-table-component';
import styles from './reportsModule.module.sass';
import TooltipGlodal from '../../components/TooltipGlodal';
import { FaEye, FaArrowRotateRight, FaBan } from 'react-icons/fa6';
import { AiOutlinePlus } from 'react-icons/ai';
import { useHistory } from 'react-router-dom';
import ViewReport from './ViewReport';
import RecoverOrganization from './RecoverOrganization';
import { customStyles } from '../../helpers/commonStyles';
import { causesListing } from '../../environment';
import SearchMain from '../../components/MainSearch';
const CausesModule = () => {
  const [causes, setCauses] = useState([]);
  const [deleteId, setDeleteId] = useState('');
  const [reportDetail, setReportDetail] = useState('');
  const [actionType, setActionType] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const [modalMessage, setModalMessage] = useState('');
  const [show, setShow] = useState(false);
  const [isRecoverShow, setIsRecoverShow] = useState(false);
  const [search,setSearch]=useState(null)
  const history = useHistory();

  const getCausesListing = async () => {
    const causesData = await axios.get(causesListing, {
      params:{...search&&{search}},
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });

    if (causesData?.data) {
      setCauses(causesData?.data?.data);
    }
  };
  useEffect(() => {
    getCausesListing();
  }, []);
  useEffect(() => {
    let timerId;
    if (search?.length > 2||!search) {
      // Clear any existing timer
      clearTimeout(timerId);
  
      // Set a new timer to trigger getUsersListing after 1 second
      timerId = setTimeout(() => {
        getCausesListing();
      }, 500);
    }
  
    // Cleanup function to clear the timer if component unmounts or search changes
    return () => {
      clearTimeout(timerId);
    };
  }, [search]);

  const columns = [
    {
      name: 'Cause Name',
      selector: (row) => row.categoryName,
    },
    {
      name: 'Description',
      selector: (row) => row.description,
    },
    {
      name: 'Created Date',
      selector: (row) => moment(row.createdAt).format('MMMM, DD, YYYY'),
    },
    // {
    //   name: 'Actions',
    //   cell: (row) => (
    //     <>
    //       <button
    //         className={styles.viewButton}
    //         onClick={() => {
    //           setDeleteId(row?._id);
    //           setReportDetail(row);
    //           setShow(true);
    //         }}
    //       >
    //         <FaEye />
    //       </button>
    //       &nbsp;&nbsp;
    //       <button
    //         className={styles.banButton}
    //         onClick={() => {
    //           setDeleteId(row?._id);
    //           setIsRecoverShow(true);
    //           setActionType('ban');
    //           setModalTitle('Deleteion Alert');
    //           setModalMessage('Are you sure you want to delete this cause ?');
    //         }}
    //       >
    //         <FaBan />
    //       </button>
    //     </>
    //   ),
    // },
  ];

  const data = causes?.categories;

  console.log({ causes, data });
  return (
    <>
      <div className={styles.settings}>
        <div className={styles.btnOuter}>
          <h3>Causes</h3>
          <button
            // className={cn('button', styles.addButton)}
            // className={cn('button', styles.addButton)}
            type='button'
            onClick={() => history.push('/create-cause')}
          >
            <AiOutlinePlus />
          </button>
        </div>
        <SearchMain onChange={(e)=>setSearch(e.target.value)} />

        <DataTable
          columns={columns}
          data={data}
          pagination
          customStyles={customStyles}
        />
      </div>
      {/* <ViewReport
        show={show}
        id={deleteId}
        setShow={setShow}
        getCausesListing={getCausesListing}
        reportDetail={reportDetail}
      />

      <RecoverOrganization
        show={isRecoverShow}
        id={deleteId}
        setShow={setIsRecoverShow}
        actionType={actionType}
        modalTitle={modalTitle}
        modalMessage={modalMessage}
        getCausesListing={getCausesListing}
      /> */}
      <TooltipGlodal />
    </>
  );
};

export default CausesModule;
