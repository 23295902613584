import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  getHomeData,
  getListings,
  getShippings,
  getTransactions,
  getUsers,
} from '../environment';
import { getRequest, postRequest } from '../apiConnection/api';
import { notifyError, notifySuccess } from '../apiConnection/notification.api';
import { useEffect } from 'react';

export function useGetNFTs(days, setNftList) {
  const queryFn = () => getRequest(getListings + '?days=' + days);
  const { data, refetch } = useQuery({
    queryKey: ['Get NFTS List'],
    queryFn,
    onError: (error) => {
      notifyError(error, 'Error while loading nfts list information');
    },
  });
  useEffect(() => {
    if (data && data.data && data.data.length !== 0) {
      setNftList(data.data);
    }
  }, [data]);
  return { refetch };
}

export function useGetNTransactions(days, setTransactionsData) {
  const queryFn = () => getRequest(getTransactions + '?days=' + days);
  const { data, refetch } = useQuery({
    queryKey: ['Get Transactions'],
    queryFn,
    enabled: false,
    onError: (error) => {
      notifyError(error, 'Error while loading transactions information');
    },
  });
  useEffect(() => {
    if (data && data.data) {
      setTransactionsData(data.data);
    }
  }, [data]);

  return { refetch };
}

export function useGetShippings(days, setShippingsData) {
  const queryFn = () => getRequest(getShippings + '?days=' + days);
  const { data, refetch } = useQuery({
    queryKey: ['Get Shippings'],
    queryFn,
    enabled: false,
    onError: (error) => {
      notifyError(error, 'Error while loading transactions information');
    },
  });
  useEffect(() => {
    if (data && data.data) {
      setShippingsData(data.data);
    }
  }, [data]);

  return { refetch };
}

export function useGetUsers(days, setUsersData) {
  const queryFn = () => {
    return getRequest(getUsers + '?days=' + days);
  };
  const { data, refetch } = useQuery({
    queryKey: ['Get Users'],
    queryFn,
    enabled: false,
    onError: (error) => {
      notifyError(error, 'Error while loading Users');
    },
  });
  useEffect(() => {
    if (data && data.data) {
      setUsersData(data.data);
    }
  }, [data]);

  return { refetch };
}

export function useGetHomeData(days, setHomeData) {
  const queryFn = () => {
    return getRequest(getHomeData + '?days=' + days);
  };
  const { data, refetch } = useQuery({
    queryKey: ['Get Home Data'],
    queryFn,
    enabled: false,
    onError: (error) => {
      notifyError(error, 'Error while loading Home');
    },
  });
  useEffect(() => {
    if (data && data.data) {
      setHomeData(data.data);
    }
  }, [data]);

  return { refetch };
}
