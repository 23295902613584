import React, { useState } from 'react';
import axios from 'axios';
import { Modal, Form } from 'react-bootstrap';
import { useFormik } from 'formik';
import cn from 'classnames';
import { twoFactorVerification } from '../../environment';
import styles from '../Settings/TwoFa/TwoFa.module.sass';
import { ThreeDots } from 'react-loader-spinner';
import { useHistory } from 'react-router-dom';
import {
  notifySuccess,
  notifyError,
} from '../../apiConnection/notification.api';
import { TwoFactorSchema } from '../Settings/TwoFa/TwoFactorSchema';

const VerifyTwoFactor = ({ show, setShow }) => {
  const history = useHistory();
  const handleClose = () => {
    setShow(false);
  };

  const [loading, setIsLoading] = useState(false);

  const {
    dirty,
    values,
    handleChange,
    handleSubmit,
    handleBlur,
    touched,
    errors,
    resetForm,
    isValid,
  } = useFormik({
    onSubmit: async (values, { setErrors }) => {
      setIsLoading(true);
      const user = JSON.parse(localStorage.getItem('user'));

      const headers = {
        Authorization: `Bearer ${user?.token}`,
      };

      try {
        const verifyOTP = await axios.post(
          twoFactorVerification,
          {
            otp: values.otp,
            email: user ? user.email : '',
          },
          {
            headers: headers,
          }
        );
        if (verifyOTP) {
          setErrors({});
          setIsLoading(false);
          notifySuccess('Login successfully');
          resetForm();
          setShow(false);
          history.push('/');
        }
      } catch (error) {
        console.log('error :', error);
        notifyError(error?.response?.data?.message);
        setIsLoading(false);
      }
    },
    initialValues: {
      otp: '',
    },
    validationSchema: TwoFactorSchema,
    validateOnBlur: true,
    validateOnMount: false,
    enableReinitialize: true,
  });

  return (
    <div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header className={styles.txt} closeButton>Two-Factor Authentication</Modal.Header>
        <Modal.Body>
          <p className={styles.txt}>
            Your account is protected with two-factor authentication. To
            continue with the sign-in you need to provide the access code from
            your authenticator or a recovery code if you have one.
          </p>
          <div className={styles.fieldset}>
            <Form.Label className={styles.txt}>Authenticator code</Form.Label>

            <input
              type='text'
              className={styles.fieldabc}
              placeholder='Two factor code'
              label='Confirm new password'
              name='otp'
              value={values.otp}
              onChange={handleChange}
              onBlur={handleBlur}
              isError={touched.otp && !!errors.otp}
              errorMessage={errors.otp}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button onClick={handleClose} className={cn('button', styles.button)}>
            Close
          </button>
          <button
            onClick={handleSubmit}
            disabled={!dirty || !isValid || loading}
            className={cn('button', styles.button)}
            style={{ background: '#198754' }}
          >
            {loading ? (
              <ThreeDots
                height='30'
                width='30'
                radius='9'
                color='#2A85FF'
                ariaLabel='three-dots-loading'
                wrapperStyle={{}}
                wrapperClassName=''
                visible={true}
              />
            ) : (
              'Verify'
            )}
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default VerifyTwoFactor;
