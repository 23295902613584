import React, { useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import cn from 'classnames';
import styles from './ResetPassword.module.sass';
import { use100vh } from 'react-div-100vh';
import { ThreeDots } from 'react-loader-spinner';
import TextInput from '../../components/TextInput';
import Image from '../../components/Image';
import { postRequest } from '../../apiConnection/api';
import { resetPasswordRequest } from '../../environment';
import {
  notifySuccess,
  notifyError,
} from '../../apiConnection/notification.api';

const SignIn = () => {
  const history = useHistory();
  const { token } = useParams();

  const heightWindow = use100vh();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setIsLoading] = useState(false);
  const handleChangePassword = (value) => {
    setPassword(value);
  };

  const handleChangeConfirmPassword = (value) => {
    setConfirmPassword(value);
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      const response = await postRequest(`${resetPasswordRequest}/${token}`, {
        password,
        confirmPassword,
      });
      if (response && response.data) {
        setTimeout(() => {
          setIsLoading(false);
          notifySuccess(response?.message);
          history.push('/sign-in');
        }, 1000);
      }
    } catch (error) {
      notifyError(error.message);
    }
  };
  return (
    <div className={styles.login} style={{ minHeight: heightWindow }}>
      <div className={styles.wrapper}>
        {/* <Link className={styles.logo} to='/'>
          <Image
            className={styles.pic}
            src='/images/content/logo.svg'
            srcDark='/images/content/logoDarkMode.svg'
            alt='Core'
          />
        </Link> */}
        <div className={cn('h4', styles.title)}>SET NEW PASSWORD</div>
        <div className={styles.body}>
          <TextInput
            className={styles.field}
            name='password'
            type='password'
            placeholder='Password'
            required
            icon='lock'
            handleChange={handleChangePassword}
          />
          <TextInput
            className={styles.field}
            name='confirmPassword'
            type='password'
            placeholder='Confirm Password'
            required
            icon='lock'
            handleChange={handleChangeConfirmPassword}
          />
          <button
            onClick={() => handleSubmit()}
            className={cn('button', styles.button)}
            disabled={loading}
          >
            {loading ? (
              <ThreeDots
                height='30'
                width='30'
                radius='9'
                color='#4fa94d'
                ariaLabel='three-dots-loading'
                wrapperStyle={{}}
                wrapperClassName=''
                visible={true}
              />
            ) : (
              'Change Password'
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
