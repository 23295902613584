export const decimalnumbervalidator = (evt, allowDecimals=false,decimals=6) => {
    let e = evt || window.event;
  
    // Check for the wheel event and prevent its default action
    if (e.key === "ArrowDown") {
      e.preventDefault();
      return;
    }
  
    // Allow: backspace, delete, tab, escape, enter and .
    const specialKeys = [46, 8, 9, 27, 13, 110];
  
    // Allow: Ctrl+A, Ctrl+C, Ctrl+V, Command+A
    if (
      specialKeys.includes(e.keyCode) ||
      ((e.keyCode === 65 ||
        e.keyCode === 67 ||
        e.keyCode === 90 ||
        e.keyCode === 88) &&
        (e.ctrlKey === true || e.metaKey === true)) ||
      (e.keyCode >= 35 && e.keyCode <= 40)
    ) {
      // let it happen, don't do anything
      return;
    }
  
    let key = e.keyCode || e.which;
  
    // Get the input value
    const input = e.target.value;
    const decimalPosition = input.indexOf('.');
  
    // If decimals are not allowed and the key is a decimal point, prevent input
    if (!allowDecimals && (key === 190 || key === 110)) {
      e.returnValue = false;
      if (e.preventDefault) e.preventDefault();
      return;
    }
  
    // If decimals are allowed, check for decimal places restriction
    if (allowDecimals) {
      // If there is a decimal point and the number of decimal places is 6 or more, prevent input
      if (decimalPosition !== -1 && input.length - decimalPosition > decimals) {
        e.returnValue = false;
        if (e.preventDefault) e.preventDefault();
        return;
      }
    }
  
    // Allow numbers and special keys
    if (
      !e.shiftKey &&
      !e.altKey &&
      !e.ctrlKey &&
      (key === 190 ||
        key === 110 ||
        (key >= 48 && key <= 57) ||
        (key >= 96 && key <= 105) ||
        key === 8 ||
        key === 9 ||
        key === 13 ||
        key === 35 ||
        key === 36 ||
        key === 37 ||
        key === 39 ||
        key === 46 ||
        key === 45)
    ) {
      // empty block statement
    } else {
      e.returnValue = false;
      if (e.preventDefault) e.preventDefault();
    }
  };
  