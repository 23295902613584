// src/redux/reducers/index.js

import { combineReducers } from 'redux';
import authReducer from './authReducer';

const rootReducer = combineReducers({
  authReducer,
  // Add other reducers here if needed
});

export default rootReducer;
