export const notifications = [
  {
    title: 'Domenica',
    login: '@domenica',
    time: '1h',
    content: 'Comment on <strong>Smiles – 3D icons</strong>',
    comment: 'Great work, I just purchased this product',
    color: '#2A85FF',
    avatar: '/images/content/avatar-1.jpg',
    icon: '/images/content/message.svg',
    new: true,
    url: '/notification',
  },
  {
    title: 'Janice',
    login: '@ethel',
    time: '2h',
    content: 'Likes <strong>Smiles – 3D icons</strong>',
    comment: 'Great work, I just purchased this product',
    color: '#FF6A55',
    avatar: '/images/content/avatar-2.jpg',
    icon: '/images/content/heart.svg',
    new: true,
    url: '/notification',
  },
  {
    title: 'Janiya',
    login: '@ethel',
    time: '4h',
    content: 'Purchased <strong>Smiles – 3D icons</strong>',
    comment: 'Great work, I just purchased this product',
    color: '#83BF6E',
    avatar: '/images/content/avatar-3.jpg',
    icon: '/images/content/shopping-bag.svg',
    new: true,
    url: '/notification',
  },
  {
    title: 'Danial',
    login: '@ethel',
    time: '6h',
    content:
      'Rate <img src="/images/content/rating-star.svg" alt="Star" /> <strong>5</strong> for <strong>Smiles – 3D icons</strong>',
    comment: 'Great work, I just purchased this product',
    color: '#8E59FF',
    avatar: '/images/content/avatar-4.jpg',
    icon: '/images/content/star.svg',
    url: '/notification',
  },
  {
    title: 'Esmeralda',
    login: '@ethel',
    time: '8h',
    content: 'Comment on <strong>Smiles – 3D icons</strong>',
    comment: 'Great work, I just purchased this product',
    color: '#2A85FF',
    avatar: '/images/content/avatar-5.jpg',
    icon: '/images/content/message.svg',
    url: '/notification',
  },
];
