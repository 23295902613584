import axios from "axios";
import React from "react";
import { Modal, Button } from "react-bootstrap";
import { destroyRole } from "../../environment";
import {
  notifySuccess,
  notifyError,
} from "../../apiConnection/notification.api";

import styles from "./reports.module.sass";

const ViewReport = ({ id, show, setShow, getRoleListing, reportDetail }) => {
  const handleClose = () => {
    setShow(false);
  };
  console.log(reportDetail, "reportDetail");

  // const banOrganization = async () => {
  //   try {
  //     const delRole = await axios.delete(`${destroyRole}/${id}`, {
  //       headers: {
  //         Authorization: `Bearer ${localStorage.getItem('token')}`,
  //       },
  //     });

  //     if (delRole?.data) {
  //       handleClose();
  //       notifySuccess(delRole?.data?.message);
  //       getRoleListing();
  //     }
  //   } catch (error) {
  //     console.log('error :', error);
  //     handleClose();
  //     notifyError(error?.response?.data?.message);
  //   }
  // };

  // const rejectRequest = async () => {
  //   try {
  //     const delRole = await axios.delete(`${destroyRole}/${id}`, {
  //       headers: {
  //         Authorization: `Bearer ${localStorage.getItem('token')}`,
  //       },
  //     });

  //     if (delRole?.data) {
  //       handleClose();
  //       notifySuccess(delRole?.data?.message);
  //       getRoleListing();
  //     }
  //   } catch (error) {
  //     console.log('error :', error);
  //     handleClose();
  //     notifyError(error?.response?.data?.message);
  //   }
  // };

  return (
    <div>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <h3>Report Detail</h3>
        </Modal.Header>
        <Modal.Body>
          <h5>Reporter Name:</h5>
          {`${reportDetail?.reportedByUser?.firstName} ${reportDetail?.reportedByUser?.lastName}`}{" "}
          <br />
          <br />
          <h5>
            {reportDetail?.reportedToUser?.role === "user"
              ? "User Name"
              : "Organization Name"}
            :
          </h5>
          {`${reportDetail?.reportedToUser?.firstName} ${reportDetail?.reportedToUser?.lastName}`}{" "}
          <br />
          <br />
          <h5>{"Report Reason:"}</h5>
          <ul className={styles.reasonList}>
            {reportDetail?.reports?.map((report) => (
            <li key={report?.id}>
            {report?.reason}{" "}
            <a
              href={
                report?.reportType === "nft"
                  ? `${process.env.REACT_APP_FRONTEND_BASE_URL}/nft/${report?.nftId}`
                  : `${process.env.REACT_APP_FRONTEND_BASE_URL}/user/${report?.reportedToUser?.username}`
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              (
              {report?.reportType === "nft"
                ? `${process.env.REACT_APP_FRONTEND_BASE_URL}/nft/${report?.nftId}`
                : `${process.env.REACT_APP_FRONTEND_BASE_URL}/user/${report?.reportedToUser?.username}`}
              )
            </a>
          </li>
          
            ))}
          </ul>
          {/* {`${reportDetail?.reports?.map(report => report?.reason).join(", ")}`} */}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleClose}>Close</Button>
          {/* <Button className='btn btn-danger'>Ban Organization</Button> */}
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ViewReport;
