import axios from "axios";
import React,{useState} from "react";
import { Modal, Button } from "react-bootstrap";
import { destroyRole } from "../../environment";
import {
  notifySuccess,
  notifyError,
} from "../../apiConnection/notification.api";
import { updateOrgEinNumber } from "../../environment";
import styles from "./reportsModule.module.sass";

const ChangeEIN = ({ organization, changeEin, setChangeEin,getReportsListing = () => {}, 
getUsersListing = () => {}, })=> {
  const [einNumber, setEinNumber] = useState("");

  const handleClose = () => {
    setChangeEin(false);
  };
  const handleEINState = (e) => {
    setEinNumber(e?.target?.value);
  };
  const updateOrgEin = async () => {
    try {
    if(einNumber?.length!==9){
      notifyError('EIN number must be exactly 9 digits.')
      return;
    }
    const response = await axios.post(
      `${updateOrgEinNumber}`,
      {
        organizationId: organization?._id,
        einNumber,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );

    if (response?.data) {
      notifySuccess(response?.data?.message);
      setEinNumber('');
      getReportsListing();
      getUsersListing();
      handleClose();
    }
      
    } catch (error) {
      console.log("error :", error);
      notifyError(error?.response?.data?.message);
      setEinNumber('');
    }
  };

  return (
    <div>
      <Modal show={changeEin} onHide={handleClose}>
        <Modal.Header closeButton>
          <h3>Change EIN</h3>
        </Modal.Header>
        <Modal.Body>
        <form
            autoComplete="off"
          >
          <div className="px-2">
            <input 
            type='number' 
            className={styles.einInput} 
            placeholder="Change EIN"  
            onChange={(e) => {
                handleEINState(e);}}
            />
          </div>
        </form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={updateOrgEin}>Update</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ChangeEIN;
