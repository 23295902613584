import React, { useEffect, useState } from "react";
import axios from "axios";
import cn from "classnames";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { AiOutlinePlus } from "react-icons/ai";
import DataTable from "react-data-table-component";
import styles from "./User.module.sass";
import TooltipGlodal from "../../components/TooltipGlodal";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import { usersList } from "../../environment";
import { FaEye, FaArrowRotateRight, FaBan } from "react-icons/fa6";

import DeleteUser from "./DeleteUser";
import { customStyles } from "../../helpers/commonStyles";
import SearchMain from "../../components/MainSearch";
import ReportModal from "../../components/ReportModal";

const Users = () => {
  const history = useHistory();
  const [users, setUsers] = useState([]);
  const [deleteId, setDeleteId] = useState("");
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [reportId, setReportId] = useState("");
  const [reportType, setReportType] = useState("User");
  const [search, setSearch] = useState(null);

  const getUsersListing = async () => {
    const getRoles = await axios.get(usersList, {
      params: { role: "user", ...(search && { search }) },

      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    if (getRoles?.data?.data) {
      setUsers(getRoles?.data?.data);
    }
  };

  useEffect(() => {
    getUsersListing();
  }, []);

  useEffect(() => {
    let timerId;
    if (search?.length > 3 || !search) {
      clearTimeout(timerId);
      timerId = setTimeout(() => {
        getUsersListing();
      }, 500);
    }
    return () => {
      clearTimeout(timerId);
    };
  }, [search]);

  const handleExportCSV = () => {
    const csvHeader = "Email Address,First Name,Last Name,Username\n";
    const csvRows = users.map((user) =>
      `${user.email},${user.firstName},${user.lastName},${user.username}`
    );
    const csvContent = csvHeader + csvRows.join("\n");
    
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "user_emails.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const columns = [
    {
      name: "Name",
      selector: (row) => `${row.firstName} ${row.lastName}`,
    },
    {
      name: "Profile Url",
      selector: (row) => (
        <a
          href={`${process.env.REACT_APP_FRONTEND_BASE_URL}/user/${row.username}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {`${process.env.REACT_APP_FRONTEND_BASE_URL}/user/${row.username}`}
        </a>
      ),
    },
    {
      name: "Email Address",
      selector: (row) => row.email,
    },
    {
      name: "Account Status",
      cell: (row) => (
        <>
          {row?.emailVerified ? (
            <span className="badge rounded-pill bg-success">Verified</span>
          ) : (
            <span className="badge rounded-pill bg-danger">Not Verified</span>
          )}
        </>
      ),
    },
    {
      name: "2FA Enabled",
      cell: (row) => (
        <>
          {row?.is2faEnabled ? (
            <span className="badge rounded-pill bg-success">Active</span>
          ) : (
            <span className="badge rounded-pill bg-warning">Not active</span>
          )}
        </>
      ),
    },
    {
      name: "Created Date",
      selector: (row) => moment(row.createdAt).format("MMMM, DD, YYYY"),
    },
    {
      name: "Actions",
      cell: (row) => (
        <>
          <button
            className={styles.viewButton}
            onClick={() => {
              setReportId(row?._id);
              setShowModal(true);
            }}
          >
            <FaEye /> Report
          </button>
        </>
      ),
    },
  ];

  return (
    <>
      <div className={styles.settings}>
        <div className={styles.btnOuter}>
          <h3>Users</h3>
          <button
            className={cn("button", styles.exportButton)}
            type="button"
            onClick={handleExportCSV}
          >
            Export as CSV
          </button>
        </div>
        <SearchMain onChange={(e) => setSearch(e.target.value)} />

        <DataTable
          columns={columns}
          data={users}
          pagination
          customStyles={customStyles}
        />
      </div>
      <DeleteUser
        show={show}
        id={deleteId}
        setShow={setShow}
        getPermissionListing={getUsersListing}
      />
      <ReportModal
        showModal={showModal}
        setShowModal={setShowModal}
        reportId={reportId}
        setReportId={setReportId}
        reportType={reportType}
        setReportType={setReportType}
      />
      <TooltipGlodal />
    </>
  );
};

export default Users;
